<template>
  <div>
    <b-card
      class="mb-1 card_body_bottom"
      :title="$t('swap_future.titles.future_contracts')"
    >
      <hr />
      <b-overlay :show="orderColumnsOverlay">
        <b-row class="mb-2 align-items-center">
          <!-- filter trade date -->
          <b-col cols="6" md="6" xl="3" lg="3" sm="6">
            <b-input-group class="compact_form_input-group">
              <flat-pickr
                :placeholder="$t('swap_future.trade_date')"
                onClose="testClose"
                v-model="tradeRangeDate"
                class="form-control compact_form_flatpickr"
                :config="dateConfig"
                @on-change="getFilterTradeDate()"
              />
              <b-input-group-append>
                <b-button
                  @click="clearTradeDate()"
                  variant="success"
                  size="sm"
                  >{{ $t("general_buttons.clear") }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- filter expiry date -->
          <b-col cols="6" md="6" xl="3" lg="3" sm="6">
            <b-input-group>
              <flat-pickr
                :placeholder="$t('swap_future.expiry_date')"
                onClose="testClose"
                v-model="expiryRangeDate"
                class="form-control"
                :config="dateConfig"
                @on-change="getFilterExpiryDate"
              />
              <b-input-group-append>
                <b-button
                  @click="clearExpiryDate()"
                  size="sm"
                  variant="success"
                  >{{ $t("general_buttons.clear") }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <!-- list page buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center list-pages-buttons"
          >
            <!-- bring unmatched trades -->
            <b-button
              v-if="$Can('bring_matched_future')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-dark"
              size="sm"
              class="compact_nav_icon-text-button mr-1"
              @click="bringUnmatchedTrades"
            >
              <feather-icon icon="ApertureIcon" size="14" />
              <span class="trades-font">{{
                $t("swap_future.buttons.bring_unmatch_trades")
              }}</span>
            </b-button>

            <!-- search for trades -->
            <b-button
              v-if="$Can('search_swap_future')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-info"
              size="sm"
              class="compact_nav_icon-text-button mr-1"
              @click="searchForTradesModal"
            >
              <feather-icon icon="SearchIcon" size="14" />
              <span class="trades-font">{{
                $t("swap_future.buttons.search_for_trades")
              }}</span>
            </b-button>
            <!-- run last search -->
            <b-button
              v-if="$Can('search_swap_future')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-dark"
              size="sm"
              class="compact_nav_icon-text-button mr-1"
              @click="runLastSearch(true)"
            >
              <feather-icon
                icon="ClockIcon"
                size="14"
                style="margin-bottom: 0.3rem;"
              />
              <span class="trades-font">{{
                $t("swap_future.buttons.run_last_search")
              }}</span>
            </b-button>
            <!-- enter new trade -->
            <b-button
              v-if="$Can('add_swap_future')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              size="sm"
              variant="gradient-success"
              class="compact_nav_icon-text-button"
              @click="createNewTrade"
            >
              <i class="fa-solid fa-plus" style="margin-bottom: 0.3rem;"></i>
              <span class="text-nowrap">{{
                $t("swap_future.buttons.enter_new_trade")
              }}</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- order columns overlay -->
        <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p>
              <strong id="form-confirm-label">{{
                $t("general_messages.save_order_columns_message")
              }}</strong>
            </p>

            <p class="mb-0" style="font-size:0.8rem;">
              {{ $t("general_messages.filter_columns_message") }}
            </p>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-start mb-md-0"
            >
              <treeselect
                value-consists-of="LEAF_PRIORITY"
                :multiple="true"
                :options="tableColumnsOptions"
                :clearable="false"
                :searchable="true"
                v-model="treeTablecolumns"
                @select="selectTreeColumn"
                @deselect="deSelectTreeColumn"
                valueFormat="object"
                :limit="9"
                :placeholder="$t('swap_future.get_selected_columns')"
                v-if="visibleSwapFutureStatusInput"
                class="treeSelect"
              />
            </b-col>
            <div class="d-flex justify-content-center mt-1">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="gradient-danger"
                class="mr-3"
                size="sm"
                @click="onCancelOrderColumn"
              >
                {{ $t("general_buttons.cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="gradient-warning"
                size="sm"
                @click="onSaveOrderColumns"
              >
                {{ $t("general_buttons.save_columns_order") }}
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>

    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <!-- Per Page && filter status && export && legend button -->
        <b-row class="mb-1 align-items-center">
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            sm="6"
            xl="2"
            lg="2"
            class="d-flex align-items-center justify-content-start mb-md-0"
            v-if="visibleSwapFutureStatusInput"
          >
            <label style="font-size:0.75rem;font-weight:400;">Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 w-100 compact_form_vue-select"
            />
            <label style="font-size:0.75rem;font-weight:400;">entries</label>
          </b-col>
          <!-- filter status -->
          <b-col
            cols="6"
            md="6"
            sm="6"
            xl="3"
            lg="3"
            class="d-flex align-items-center justify-content-start mb-md-0"
          >
            <treeselect
              value-consists-of="LEAF_PRIORITY"
              :multiple="true"
              :options="statusOptions"
              v-model="treeStatus"
              valueFormat="object"
              :limit="4"
              clearable
              :placeholder="
                treeStatus.length === 0 ? $t('swap_future.selections') : null
              "
              v-if="visibleSwapFutureStatusInput"
              class="treeSelect compact_form_vue-treeselect"
            />
          </b-col>
          <!-- export && legend button -->

          <!-- legend -->
          <b-col
            cols="12"
            xl="6"
            lg="6"
            md="8"
            sm="12"
            v-if="allSwapFuturesData.length > 0"
          >
            <b-table-simple
              hover
              small
              caption-top
              responsive
              bordered
              style="margin-bottom: 0 !important"
            >
              <b-tbody
                class="d-flex align-items-center justify-content-end gap-1"
                style="font-size:0.6rem;gap:5px;"
              >
                <b-tr>
                  <td style="width: 30%;" class="white-space">
                    {{ $t("equity_bond.legend_table.execution") }}
                  </td>
                  <td style="background-color: paleturquoise;"></td>
                </b-tr>
                <b-tr>
                  <td class="white-space">
                    {{ $t("equity_bond.legend_table.verified") }}
                  </td>
                  <td style="background-color: springgreen;"></td>
                </b-tr>
                <b-tr>
                  <td class="white-space">
                    {{ $t("equity_bond.legend_table.journal_created") }}
                  </td>
                  <td style="background-color: mediumpurple;"></td>
                </b-tr>
                <b-tr>
                  <td class="white-space">
                    {{ $t("equity_bond.legend_table.expired_order") }}
                  </td>
                  <td style="background-color: silver;"></td>
                </b-tr>
                <b-tr>
                  <td class="white-space">
                    {{ $t("equity_bond.legend_table.cancelled") }}
                  </td>
                  <td style="background-color: #ffb8b8 !important;"></td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
          <!-- export excell -->
          <b-col
            cols="6"
            md="4"
            sm="12"
            xl="1"
            lg="1"
            class="d-flex justify-content-end align-items-center"
            v-if="visibleSwapFutureStatusInput"
            style="margin-left: auto !important"
          >
            <b-button
              id="info-export-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              size="sm"
              class="compact_nav_icon-text-button"
            >
              <feather-icon
                icon="DownloadIcon"
                size="14"
                style="margin-bottom: 0.3rem;"
              />

              <span class="text-nowrap">{{
                $t("swap_future.buttons.export")
              }}</span>
            </b-button>
            <b-popover
              target="info-export-button"
              placement="right"
              triggers="click blur"
            >
              <template #title>
                {{ $t("swap_future.buttons.export") }}
              </template>

              <b-button
                size="sm"
                class="compact_nav_icon-text-button"
                @click="exportXlsx()"
              >
                <span class="text-nowrap">Save as .xlsx</span>
              </b-button>

              <b-button
                size="sm"
                class="compact_nav_icon-text-button mt-1"
                @click="exportCsv()"
              >
                <span class="text-nowrap">Save as .csv</span>
              </b-button>
            </b-popover>
          </b-col>
        </b-row>

        <!-- list table -->

        <div
          @contextmenu.prevent="$refs.contextMenu.open"
          v-if="allSwapFuturesData.length > 0"
        >
          <div
            style="background-color:white;position:relative;"
            :style="tableContainerStyle"
            id="table-container"
          >
            <b-table
              :sticky-header="true"
              :no-border-collapse="true"
              hover
              :bordered="true"
              ref="refSwapFutureTable"
              class="compact_table"
              thead-class="animate__animated animate__fadeIn"
              :items="filteredTrades"
              :filter="filter"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              selectable
              select-mode="single"
              :small="true"
              head-variant="dark"
              :fields="tableColumns"
              show-empty
              empty-text="No matching records found"
              :current-page="currentPage"
              :per-page="perPage"
              :tbody-tr-class="legendClass"
              caption-top
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortByDesc"
            >
              <!-- @row-clicked="onRowSelected" -->

              <!-- sort columns draggable -->
              <template slot="thead-top" slot-scope="data">
                <draggable
                  v-model="tableColumns"
                  tag="tr"
                  id="draggableColumns"
                  style="display: none;"
                  class="animate__animated animate__fadeIn compact_draggable_table_columns"
                >
                  <th
                    v-for="header in tableColumns"
                    :key="header.key"
                    scope="col"
                    style="white-space: nowrap;padding: 1rem;cursor:grab"
                  >
                    {{ header.label }}
                  </th>
                </draggable>
              </template>

              <!-- filter columns -->
              <template
                slot="top-row"
                slot-scope="{ fields }"
                v-if="showColumnsFilter"
              >
                <td
                  :class="field.key == 'ID' ? 'sticky-column' : ''"
                  v-for="field in tableColumns"
                  :key="field.key"
                >
                  <v-select
                    v-if="field.key == 'EntryType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form_vue-select'
                    "
                    :options="optEntryTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <input
                    v-else-if="field.key == 'ID'"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="'ID_column_search_input compact_form-input'"
                    style="max-width: 75px;"
                  />
                  <input
                    v-else
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="
                      field.key + ' column_search_input compact_form-input'
                    "
                  />
                  <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
                </td>
              </template>

              <!-- Column: ID -->
              <template #cell(ID)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <b
                    ><span class="font-weight-bold text-center ">
                      <strong style="text-decoration:underline;">{{
                        data.item.ID ? data.item.ID : "-"
                      }}</strong>
                    </span>
                  </b>
                </div>
                <!-- trade controls -->
                <div
                  v-if="!showMultiplecheckboks && filteredTrades.length > 0"
                  class="d-flex justify-content-center align-items-center compact_controls_button"
                >
                  <b-button
                    :id="
                      'trade-controls-' + data.item
                        ? data.item.ID.toString()
                        : ''
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="top-btns animate__animated animate__fadeIn"
                    @click="
                      showTradeControlsPopover(
                        'trade-controls-' + data.item
                          ? data.item.ID.toString()
                          : '',
                        data.item
                      )
                    "
                  >
                    <i class="fa-solid fa-bars"></i>
                  </b-button>
                  <b-popover
                    :target="
                      'trade-controls-' + data.item
                        ? data.item.ID.toString()
                        : ''
                    "
                    triggers="focus"
                    variant="primary"
                  >
                    <template #title>
                      {{ $t("swap_future.titles.trade_controls") }}
                    </template>

                    <b-button
                      v-if="$Can('verify_swap_future') && seeVerifyButton"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      :title="$t('swap_future.buttons.verify_trade')"
                      size="sm"
                      class="top-btns mt-1"
                      @click="verifySelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="CheckSquareIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.verify_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('amend_swap_future') && seeAmendButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-warning"
                      size="sm"
                      class="top-btns mt-1"
                      @click="amendUpdate(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EditIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.amend_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('view_swap_future')"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="viewSelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="EyeIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.view_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('copy_swap_future') && seeCopyButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="copySelectedTrade(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="CopyIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.copy_trade") }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="
                        $Can('history_swap_future') && seeAmendDetailsButton
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="getAmendDetails(data.item)"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="ListIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("swap_future.buttons.get_amend_details")
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="$Can('cancel_swap_future') && seeCancelledButton"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      class="top-btns mt-1"
                      @click="cancelSelectedTrade(data.item)"
                      :disabled="data.item.Cancelled == 1"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="XSquareIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.cancel_trade") }}</span
                      >
                    </b-button>
                    <!-- show auto match button -->
                    <b-button
                      v-if="
                        $Can('autoMatch_swap_future') &&
                          seeAutoMatchSameContracts
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="autoMatchSameContracts(data.item)"
                      :disabled="data.item.Cancelled == 1"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="GitMergeIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("swap_future.buttons.automatch_same_contract")
                        }}</span
                      >
                    </b-button>
                    <!-- show manual match button -->
                    <b-button
                      v-if="
                        $Can('manualMatch_swap_future') &&
                          seeManualMatchSameContracts
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="manualMatchSameContracts(data.item)"
                      :disabled="data.item.Cancelled == 1"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="GitMergeIcon"
                          style="margin-right: 0.3rem;"
                        />{{
                          $t("swap_future.buttons.manual_same_contract")
                        }}</span
                      >
                    </b-button>

                    <b-button
                      v-if="
                        $Can('getPaired_swap_future') && seeGetPairedContracts
                      "
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="top-btns mt-1"
                      @click="getPairedSelectedTrade(data.item)"
                      :disabled="data.item.Cancelled == 1"
                    >
                      <span class="trades-font">
                        <feather-icon
                          icon="GitBranchIcon"
                          style="margin-right: 0.3rem;"
                        />{{ $t("swap_future.buttons.get_paired_trade") }}</span
                      >
                    </b-button>
                  </b-popover>
                </div>
                <!-- multiple checkboks trade controls -->
                <div
                  v-if="showMultiplecheckboks"
                  class="d-flex justify-content-center align-items-center compact_controls_button"
                >
                  <b-form-checkbox
                    plain
                    v-model="data.item.selectedTrade"
                    @change="multiSelectRow(data.item.selectedTrade, data.item)"
                    class="animate__animated animate__fadeIn"
                  >
                  </b-form-checkbox>
                </div>
              </template>

              <!-- Column: TcUti -->
              <template #cell(TcUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUti ? data.item.TcUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>
              <!-- Column: EntryType -->
              <template #cell(EntryType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.EntryType ? data.item.EntryType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderDate -->
              <template #cell(OrderDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderDate
                        ? dateFormat(data.item.OrderDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Trade Date -->
              <template #cell(TradeDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TradeDate != null
                        ? dateFormat(data.item.TradeDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Expiry Date -->
              <template #cell(ExpiryDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExpiryDate
                        ? dateFormat(data.item.ExpiryDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client -->
              <template #cell(Client)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Client ? data.item.Client : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientAccount -->
              <template #cell(ClientAcc)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientAcc ? data.item.ClientAcc : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: BuySell -->
              <template #cell(BuySell)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.BuySell ? data.item.BuySell : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ContractName -->
              <template #cell(ContractName)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ContractName ? data.item.ContractName : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: number # of Contracts -->
              <template #cell(NumberOfContracts)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.NumberOfContracts
                        ? data.item.NumberOfContracts
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Contract size -->
              <template #cell(ContractSize)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ContractSize
                        ? formatPrice(data.item.ContractSize, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client Price -->
              <template #cell(ClientPrice)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientPrice
                        ? formatPrice(data.item.ClientPrice, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client Spread -->
              <template #cell(ExchangeFee)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExchangeFee
                        ? formatPrice(data.item.ExchangeFee, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Ccy -->
              <template #cell(Ccy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Ccy ? data.item.Ccy : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client Comm Per Contract -->
              <template #cell(ClientCommPerContract)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientCommPerContract
                        ? formatPrice(data.item.ClientCommPerContract, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Total Client Comm -->
              <template #cell(ClientCommTotal)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientCommTotal
                        ? formatPrice(data.item.ClientCommTotal, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: counterparty ref -->
              <template #cell(CpRef)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CpRef ? data.item.CpRef : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Cross reference && CrossRef -->
              <template #cell(CrossRef)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CrossRef ? data.item.CrossRef : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Execution Price -->
              <template #cell(ExecutionPrice)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExecutionPrice
                        ? formatPrice(data.item.ExecutionPrice, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Spot Ref -->
              <template #cell(SpotRef)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SpotRef
                        ? formatPrice(data.item.SpotRef, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ExecutionTime -->
              <template #cell(ExecTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExecTime ? data.item.ExecTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ExchangeTraded -->
              <template #cell(ExchangeTraded)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExchangeTraded ? data.item.ExchangeTraded : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Ticker -->
              <template #cell(Ticker)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Ticker ? data.item.Ticker : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: total collateral -->
              <template #cell(CollTotal)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CollTotal
                        ? formatPrice(data.item.CollTotal, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Coll Per Contract -->
              <template #cell(CollPerContract)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CollPerContract
                        ? formatPrice(data.item.CollPerContract, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Order Type -->
              <template #cell(OrderType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderType ? data.item.OrderType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Cpty Comm Per Contract -->
              <template #cell(CptyCommPerContract)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyCommPerContract
                        ? formatPrice(data.item.CptyCommPerContract, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Total Cpty Comm -->
              <template #cell(CptyCommTotal)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyCommTotal
                        ? formatPrice(data.item.CptyCommTotal, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Net Commission -->
              <template #cell(NetComm)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.NetComm
                        ? formatPrice(data.item.NetComm, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Spread Gain -->
              <template #cell(SpreadGain)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SpreadGain
                        ? formatPrice(data.item.SpreadGain, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Total TC Profit -->
              <template #cell(TotalTcProfit)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TotalTcProfit
                        ? formatPrice(data.item.TotalTcProfit, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Isin -->
              <template #cell(Isin)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Isin ? data.item.Isin : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Hedge/Spec -->
              <template #cell(HedgeSpec)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.HedgeSpec ? data.item.HedgeSpec : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Counterparty UTI -->
              <template #cell(CptyUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyUti ? data.item.CptyUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: UniqueLinkId -->
              <template #cell(UniqueLinkId)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SettlementType -->
              <template #cell(SettlementType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SettlementType ? data.item.SettlementType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SettlementDate -->
              <template #cell(SettlementDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SettlementDate != null
                        ? dateFormat(data.item.SettlementDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTime -->
              <template #cell(OrderTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTime ? data.item.OrderTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Counterparty Trader -->
              <template #cell(CptyTrader)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyTrader ? data.item.CptyTrader : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TcUser -->
              <template #cell(TcUser)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUser ? data.item.TcUser : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTakenVia -->
              <template #cell(OrderTakenVia)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderGivenThrough -->
              <template #cell(OrderGivenThrough)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderGivenThrough
                        ? data.item.OrderGivenThrough
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>
              <!-- Column: Counterparty -->
              <template #cell(Cpty)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Cpty ? data.item.Cpty : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyAccount -->
              <template #cell(CptyAcc)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyAcc ? data.item.CptyAcc : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TraderNote -->
              <template #cell(TraderNote)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TraderNote ? data.item.TraderNote : "-"
                    }}</strong>
                  </span></b
                >
              </template>
              <!-- Column: SavedBy -->
              <template #cell(SavedBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SavedBy ? data.item.SavedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveDate -->
              <template #cell(SaveDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SaveDate != null
                        ? dateFormat(data.item.SaveDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveTime -->
              <template #cell(SaveTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SaveTime != null ? data.item.SaveTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendedBy -->
              <template #cell(AmendedBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendedBy ? data.item.AmendedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentDate -->
              <template #cell(AmendmentDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentDate != null
                        ? dateFormat(data.item.AmendmentDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentTime -->
              <template #cell(AmendmentTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentTime != null
                        ? data.item.AmendmentTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentReason -->
              <template #cell(AmendmentReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentReason
                        ? data.item.AmendmentReason
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Cancelled -->
              <template #cell(Cancelled)="data">
                <b
                  ><span class="font-weight-bold text-white">
                    <strong>{{
                      data.item.Cancelled == 1 ? "Cancelled" : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelledBy -->
              <template #cell(CancelledBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelledBy ? data.item.CancelledBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelDate -->
              <template #cell(CancelDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelDate != null
                        ? dateFormat(data.item.CancelDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelTime -->
              <template #cell(CancelTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelTime != null ? data.item.CancelTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelReason -->
              <template #cell(CancelReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelReason ? data.item.CancelReason : "-"
                    }}</strong>
                  </span></b
                >
              </template>
            </b-table>
          </div>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkbox"
                  : "Hide multiple checkbox"
              }}</span>
            </b-link>
          </li>
          <li v-if="!showOrderColumns">
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('orderTableColumns')"
            >
              <i class="fa-solid fa-arrow-up-wide-short"></i>
              <span class="ml-75">{{ "Sort columns" }}</span>
            </b-link>
          </li>
          <li v-if="selectedMultipleTrades.length > 0">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon icon="CopyIcon" size="16" />
              <span class="ml-75">{{
                $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>
        <div class="mt-1" v-if="allSwapFuturesData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted" style="font-size:0.8rem"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 compact_paginate"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="allSwapFuturesData.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{ $t("general_title.not_found_data") }}</strong>
                <br />
                {{
                  $t("swap_future.messages.not_found_swap_future_data")
                }}</span
              >
            </div>
          </b-alert>
        </div>

        {{ /*search for trades modal*/ }}
        <SearchTrades
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="searchTrades"
          @searchedData="getSearchedData"
        />

        {{ /*Future contract for trades modal*/ }}
        <FutureContractFormModal
          :opt-counter-parties.sync="optCounterParties"
          :opt-clients.sync="optClients"
          :opt-cost-centers.sync="optCostCenters"
          :searched-clients.sync="searchedClients"
          :searched-counterparties.sync="searchedCounterparties"
          ref="refFutureContractFormModal"
          :formTitle="formTitle"
          :formType="formType"
          :formOkButtonText="formOkButtonText"
          @afterFormSubmitOperation="afterFormSubmitOperation"
          @manualMatchPositions="showManualMatchPositionModal"
        />

        {{ /*view position entries modal*/ }}
        <ViewPositionEntries
          ref="refViewPositionEntries"
          @createdPositionEntry="createdPositionEntry"
          @afterFormSubmitOperation="afterFormSubmitOperation"
        />
      </b-card>
    </b-overlay>
    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.failes_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(Tcuti)="data">
                  <strong>{{ data.item.Tcuti }}</strong>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import swapFutureStoreModule from "./swapFutureStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState, mapGetters, mapActions } from "vuex";
import SearchTrades from "./SearchTrades.vue";
import FutureContractFormModal from "./FutureContractFormModal.vue";
import ViewPositionEntries from "./ViewPositionEntries.vue";
import VueHorizontal from "vue-horizontal";
import VueContext from "vue-context";
import { debounce } from "lodash";
import draggable from "vuedraggable";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    SearchTrades,
    FutureContractFormModal,
    ViewPositionEntries,
    Treeselect,
    VueHorizontal,
    VueContext,
    draggable,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const SWAPFUTURE_APP_STORE_MODULE_NAME = "swapFuture";
    // Register module
    if (!store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SWAPFUTURE_APP_STORE_MODULE_NAME,
        swapFutureStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SWAPFUTURE_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getOrderSwapFutureColumns();

    //option status
    let treeOptionsStatus = [
      {
        id: 1000000,
        label: this.$t("swap_future.status"),
        children: [],
      },
    ];
    this.swapFutureStatusOptions.forEach((element) => {
      let setStatus = {
        id: element.text,
        label: element.text,
        value: element.text,
        is: "status",
      };
      treeOptionsStatus[0].children.push(setStatus);
    });

    this.statusOptions = treeOptionsStatus;
    //************************** */

    //get last 7 days trades
    this.getLast7DaysTrades();
    this.getAllAccounts();

    //   this.getArrangeSwapFutureOrderTakenGivenThrough();

    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    const swapFutureStatusOptions = [
      { text: "Execution" },
      { text: "Expired Order" },
      { text: "Verified" },
      { text: "Cancelled" },
    ];

    const swapFuturetableColumns = [
      // { key: "show_details", label: null },
      {
        stickyColumn: true,
        key: "ID",
        label: "ID",
        // thClass: "text-left",
        tdClass: "text-center ID_background",
        thClass: "sticky-column",
        thStyle: "width: 50px",
        sortable: true,
        class: "text-start table-padding sticky-column",
        variant: "primary",
      },
      {
        key: "EntryType",
        label: this.$t("equity_bond.table.entry_type"),
        class: "text-start table-padding",
      },
      {
        key: "TcUti",
        label: this.$t("swap_future.table.tc_uti"),
        class: "text-start table-padding",
      },
      {
        key: "TradeDate",
        label: this.$t("swap_future.table.trade_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "OrderDate",
        label: this.$t("equity_bond.table.order_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "OrderTime",
        label: this.$t("swap_future.table.order_time"),
        class: "text-start table-padding",
      },
      {
        key: "ExpiryDate",
        label: this.$t("swap_future.table.expiry_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "Client",
        label: this.$t("swap_future.table.client"),
        class: "text-start table-padding",
        sortable: true,
      },
      {
        key: "ClientAcc",
        label: this.$t("swap_future.table.client_account"),
        class: "text-start table-padding",
      },
      {
        key: "BuySell",
        label: this.$t("swap_future.table.buy_sell"),
        class: "text-start table-padding",
      },
      {
        key: "OrderType",
        label: this.$t("swap_future.table.order_type"),
        class: "text-start table-padding",
      },
      {
        key: "ContractName",
        label: this.$t("swap_future.table.contract_name"),
        class: "text-start table-padding",
      },
      {
        key: "NumberOfContracts",
        label: this.$t("swap_future.table.of_contracts"),
        class: "text-start table-padding",
      },
      {
        key: "ContractSize",
        label: this.$t("swap_future.table.contract_size"),
        class: "text-start table-padding",
      },
      {
        key: "ClientPrice",
        label: this.$t("swap_future.table.client_price"),
        class: "text-start table-padding",
      },
      {
        key: "ExchangeFee",
        label: this.$t("swap_future.table.exchange_fee"),
        class: "text-start table-padding",
      },
      {
        key: "Ccy",
        label: this.$t("swap_future.table.ccy"),
        class: "text-start table-padding",
      },
      {
        key: "ClientCommPerContract",
        label: this.$t("swap_future.table.client_per_contract"),
        class: "text-start table-padding",
      },
      {
        key: "ClientCommTotal",
        label: this.$t("swap_future.table.total_client_comm"),
        class: "text-start table-padding",
      },
      {
        key: "CpRef",
        label: this.$t("swap_future.table.counterparty_ref"),
        class: "text-start table-padding",
      },
      {
        key: "CrossRef",
        label: this.$t("swap_future.table.cross_reference"),
        class: "text-start table-padding",
      },
      {
        key: "ExecutionPrice",
        label: this.$t("swap_future.table.execution_price"),
        class: "text-start table-padding",
      },
      {
        key: "SpotRef",
        label: this.$t("swap_future.table.spot_ref"),
        class: "text-start table-padding",
      },
      {
        key: "ExecTime",
        label: this.$t("swap_future.table.execution_time"),
        class: "text-start table-padding",
      },
      {
        key: "ExchangeTraded",
        label: this.$t("swap_future.table.exchange_traded"),
        class: "text-start table-padding",
      },
      {
        key: "Ticker",
        label: this.$t("swap_future.table.ticker"),
        class: "text-start table-padding",
      },
      {
        key: "CollPerContract",
        label: this.$t("swap_future.table.coll_per_contract"),
        class: "text-start table-padding",
      },
      {
        key: "CollTotal",
        label: this.$t("swap_future.table.total_colleteral"),
        class: "text-start table-padding",
      },
      {
        key: "CptyCommPerContract",
        label: this.$t("swap_future.table.cpty_per_contract"),
        class: "text-start table-padding",
      },
      {
        key: "CptyCommTotal",
        label: this.$t("swap_future.table.total_cpty_comm"),
        class: "text-start table-padding",
      },
      {
        key: "NetComm",
        label: this.$t("swap_future.table.net_comm"),
        class: "text-start table-padding",
      },
      {
        key: "SpreadGain",
        label: this.$t("swap_future.table.spread_gain"),
        class: "text-start table-padding",
      },
      {
        key: "TotalTcProfit",
        label: this.$t("swap_future.table.total_tc_profit"),
        class: "text-start table-padding",
      },
      {
        key: "Isin",
        label: this.$t("swap_future.table.isin"),
        class: "text-start table-padding",
      },
      {
        key: "HedgeSpec",
        label: this.$t("swap_future.table.hedge_spec"),
        class: "text-start table-padding",
      },
      {
        key: "CptyUti",
        label: this.$t("swap_future.table.cpty_uti"),
        class: "text-start table-padding",
      },
      {
        key: "UniqueLinkId",
        label: this.$t("swap_future.table.unique_link_id"),
        class: "text-start table-padding",
      },
      {
        key: "SettlementType",
        label: this.$t("swap_future.table.settlement_type"),
        class: "text-center table-padding",
      },
      {
        key: "SettlementDate",
        label: this.$t("swap_future.table.settlement_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },

      {
        key: "CptyTrader",
        label: this.$t("swap_future.table.counterparty_trader"),
        class: "text-start table-padding",
      },
      {
        key: "TcUser",
        label: this.$t("swap_future.table.sfc_user"),
        class: "text-start table-padding",
      },

      {
        key: "OrderTakenVia",
        label: this.$t("swap_future.table.order_taken_via"),
        class: "text-start table-padding",
      },
      {
        key: "OrderGivenThrough",
        label: this.$t("swap_future.table.order_given_through"),
        class: "text-start table-padding",
      },
      {
        key: "Cpty",
        label: this.$t("swap_future.table.counterparty"),
        class: "text-start table-padding",
      },
      {
        key: "CptyAcc",
        label: this.$t("swap_future.table.counterparty_account"),
        class: "text-start table-padding",
      },

      {
        key: "TraderNote",
        label: this.$t("swap_future.table.note"),
        class: "text-start table-padding",
      },

      {
        key: "SavedBy",
        label: this.$t("swap_future.table.saved_by"),
        class: "text-start table-padding",
      },
      {
        key: "SaveDate",
        label: this.$t("swap_future.table.save_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "SaveTime",
        label: this.$t("swap_future.table.save_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendedBy",
        label: this.$t("swap_future.table.amended_by"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentDate",
        label: this.$t("swap_future.table.amendment_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "AmendmentTime",
        label: this.$t("swap_future.table.amendment_time"),
        class: "text-start table-padding",
      },
      {
        key: "AmendmentReason",
        label: this.$t("swap_future.table.amendment_reason"),
        class: "text-start table-padding",
      },
      {
        key: "Cancelled",
        label: this.$t("swap_future.table.cancelled"),
        class: "text-start table-padding",
      },
      {
        key: "CancelledBy",
        label: this.$t("swap_future.table.cancelled_by"),
        class: "text-start table-padding",
      },
      {
        key: "CancelDate",
        label: this.$t("swap_future.table.canceled_date"),
        class: "text-start table-padding text-nowrap",
      },
      {
        key: "CancelTime",
        label: this.$t("swap_future.table.cancelled_time"),
        class: "text-start table-padding",
      },
      {
        key: "CancelReason",
        label: this.$t("swap_future.table.cancelled_reason"),
        class: "text-start table-padding",
      },
    ];

    return {
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.id"
          ),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "Tcuti",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.tcUti"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 30%",
        },
        {
          key: "reason",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.reason"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 50%",
        },
      ],
      userData: JSON.parse(localStorage.getItem("userData")),
      windowHeight: window.innerHeight,
      allSwapFuturesData: [],
      allSwapFutureNotFilterData: [],
      visibleSwapFutureStatusInput: false,
      optInstrumentTypes: [
        { title: "All", value: "" },
        { title: "EQ", value: "EQ" },
      ],
      optbuySells: [
        { title: "All", value: "" },
        { title: "Buy", value: "Buy" },
        { title: "Sell", value: "Sell" },
        { title: "Sell Short", value: "Sell Short" },
      ],
      optEntryTypes: [
        { title: "All", value: "" },
        { title: "Order", value: "Order" },
        { title: "Execution", value: "Execution" },
      ],
      optOrderTypes: [
        { title: "All", value: "" },
        { title: "Limit", value: "Limit" },
        { title: "Market", value: "Market" },
        { title: "Stop Limit", value: "Stop Limit" },
        { title: "Stop", value: "Stop" },
        { title: "Trailing Stop", value: "Trailing Stop" },
      ],
      optTimeInForces: [
        { title: "All", value: "" },
        { title: "DAY", value: "DAY" },
        { title: "GTC", value: "GTC" },
        { title: "GTD", value: "GTD" },
        { title: "FOK", value: "FOK" },
        { title: "FAK", value: "FAK" },
      ],
      optActingCapacities: [
        { title: "All", value: "" },
        { title: "CASH", value: "CASH" },
        { title: "PRINCIPAL", value: "PRINCIPAL" },
      ],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      sortByDesc: true,
      sortBy: "ID",
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: null,
      tableOrjinalColumns: null,
      swapFuturetableColumns: swapFuturetableColumns,
      filters: {
        EntryType: "",
        TcUti: "",
        Counterparty: "",
      },
      perPage: localStorage.getItem("swapFutureTradesShow") ?? 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      filter: null,
      loading: false,
      optContractNames: [],
      filterTradeDate: null,
      filterExpiryDate: null,
      canceledReasonText: null,
      statusOptions: [],
      tableColumnsOptions: [],
      swapFutureStatusOptions: swapFutureStatusOptions,
      treeStatus: [],
      treeTablecolumns: [],
      tradeRangeDate: null,
      filterTradeDateStart: null,
      filterTradeDateEnd: null,
      expiryRangeDate: null,
      filterExpiryDateDateStart: null,
      filterExpiryDateDateEnd: null,
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
      },

      optClients: [],
      optCounterParties: [],
      searchedClients: [],
      searchedCounterparties: [],
      optCostCenters: [],
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      showOrderColumns: false,
      orderColumnsOverlay: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeAmendDetailsButton: false,
      seeAmendButton: false,
      seeCopyButton: false,
      seeVerifyButton: false,
      seeJournalButton: false,
      seeJorurnalEntriesButton: false,
      seeReversalInitiatedButton: false,
      seeUndoInitiatedButton: false,
      seeReversedButton: false,
      seeCancelledButton: false,
      seeAutoMatchSameContracts: false,
      seeManualMatchSameContracts: false,
      seeGetPairedContracts: false,
      formType: null,
      formTitle: null,
      formOkButtonText: null,
    };
  },
  computed: {
    ...mapState({
      lastSearchData: (state) => state.runLastSwapFutureSearchData,
      userColumnsData: (state) => state.usersOrderColumnsSwapFutureData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["getUserOrderColumnsSwapFuture"]),

    filteredTrades() {
      const filtered = this.allSwapFuturesData.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0 ? filtered : filtered;
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
    tableContainerStyle() {
      const screenHeight = window.innerHeight;
      let tableHeightVh;

      let count = this.allSwapFuturesData.length;

      if (count >= 7) {
        tableHeightVh = ((screenHeight * 0.5) / screenHeight) * 100; // Ekranın %50'si
      } else if (count >= 5) {
        tableHeightVh = ((screenHeight * 0.5) / screenHeight) * 100; // Ekranın %40'ı
      } else if (count >= 3) {
        tableHeightVh = ((screenHeight * 0.3) / screenHeight) * 100; // Ekranın %30'u
      } else {
        tableHeightVh = ((screenHeight * 0.2) / screenHeight) * 100; // Ekranın %20'si
      }

      return `height:${tableHeightVh}vh;max-height:${tableHeightVh}vh;`;
    },
  },
  methods: {
    ...mapActions(["saveOrderColumnsSwapFuture", "getOrderColumnsSwapFuture"]),
    getWindowHeight() {
      this.windowHeight = window.innerHeight;

      this.updateStickyHeaderHeight();
    },

    updateStickyHeaderHeight() {
      this.$nextTick(() => {
        const headerElements = document.getElementsByClassName(
          "b-table-sticky-header"
        );
        if (headerElements.length > 0) {
          const newHeight = window.innerHeight - 550;
          this.stickyHeaderStyle = `height: ${newHeight}px`;
        }
      });
    },

    showTradeControlsPopover(targetTrade, trade) {
      if (trade) {
        // set default
        this.seeAmendDetailsButton = false;
        this.seeVerifyButton = false;
        this.seeAmendButton = false;
        this.seeCopyButton = false;
        this.seeJournalButton = false;
        this.seeJorurnalEntriesButton = false;
        this.seeReversalInitiatedButton = false;
        this.seeUndoInitiatedButton = false;
        this.seeReversedButton = false;
        this.seeCancelledButton = false;
        this.seeAutoMatchSameContracts = false;
        this.seeManualMatchSameContracts = false;
        this.seeGetPairedContracts = false;

        // set suitable flow buttons

        // see amend details
        if (
          trade.AmendedBy != null &&
          trade.AmendmentDate != null &&
          trade.AmendmentTime != null &&
          trade.Cancelled == 0
        ) {
          this.seeAmendDetailsButton = true;
        }

        // see amend trade button

        if (trade.Cancelled == 0) {
          this.seeAmendButton = true;
        }
        // see copy trade button
        if (trade.Cancelled == 0) {
          this.seeCopyButton = true;
        }

        // see verify
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeVerifyButton = true;
        }
        // see journal
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.JournalCreated == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeJournalButton = true;
        }

        // see trade journalEntries
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.Cancelled == 0
        ) {
          this.seeJorurnalEntriesButton = true;
        }

        // see revers initiated
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeReversalInitiatedButton = true;
        }

        // see undo initiated
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 1 &&
          trade.Reversed == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeUndoInitiatedButton = true;
        }

        // see reversed
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.JournalCreated == 1 &&
          trade.ReversalInitiated == 1 &&
          trade.Reversed == 0 &&
          trade.Cancelled == 0
        ) {
          this.seeReversedButton = true;
        }

        // if cancelled trade it can not do
        if (
          trade.CancelDate == null &&
          trade.Cancelled == 0 &&
          trade.CancelTime == null
        ) {
          this.seeCancelledButton = true;
        }

        // if auto && manual match same contracts client trade
        if (
          trade.EntryType == "Execution" &&
          trade.Verified == 1 &&
          trade.LaterMatch == true &&
          trade.BuySell == "Sell" &&
          trade.CancelDate == null &&
          trade.Cancelled == 0 &&
          trade.CancelTime == null
        ) {
          this.seeAutoMatchSameContracts = true;
          this.seeManualMatchSameContracts = true;
        }

        // if get paired trade
        if (
          trade.CancelDate == null &&
          trade.Cancelled == 0 &&
          trade.CancelTime == null &&
          trade.CrossRef != null
        ) {
          this.seeGetPairedContracts = true;
        }
      }

      this.$root.$emit("bv::show::popover", targetTrade);
    },
    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }
      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];

        // this.tableColumns[1].class = this.showMultiplecheckboks
        //   ? "text-center"
        //   : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allSwapFuturesData, (v) => (v.selectedTrade = false));
      }
      if (click == "orderTableColumns") {
        this.showOrderColumns = !this.showOrderColumns;
        this.orderColumnsOverlay = true;
        // hide main columns
        this.$refs.refSwapFutureTable.$el.classList.add("hide-rows");

        // visible draggable order columns
        var draggableColumns = document.getElementById("draggableColumns");
        draggableColumns.style.display = "";
      }
    },
    getOrderSwapFutureColumns() {
      this.getOrderColumnsSwapFuture()
        .then((res) => {
          // get order user columns
          if (
            this.userColumnsData.find((data) => data.user == this.user.name)
          ) {
            let orderedColumnsData = this.getUserOrderColumnsSwapFuture;
            this.tableColumns = orderedColumnsData;
            this.tableOrjinalColumns = this.swapFuturetableColumns;
          } else {
            this.tableColumns = this.swapFuturetableColumns;
            this.tableOrjinalColumns = this.swapFuturetableColumns;
          }

          //******************************* */

          // tree columns options
          let treeColumnsOption = [
            {
              id: 1000000,
              label: this.$t("swap_future.columns"),
              children: [],
            },
          ];

          this.tableOrjinalColumns.forEach((element, index) => {
            if (element.key != "ID") {
              var setColumn = {
                id: index,
                label: element.key,
                value: element.key,
                is: "column",
              };
              treeColumnsOption[0].children.push(setColumn);

              if (
                this.tableColumns.find((column) => column.key === element.key)
              ) {
                this.treeTablecolumns.push(setColumn);
              }
            }
          });
          this.tableColumnsOptions = treeColumnsOption;

          //this.treeTablecolumns = treeColumnsOption[0].children;
          //******************* */
        })
        .catch((error) => {
          this.wrongToastMessage(
            this.$t("general_messages.columns_data_notfound")
          );
          console.log(error);
        });
    },
    onSaveOrderColumns() {
      if (this.tableColumns && this.tableColumns.length > 0) {
        this.saveOrderColumnsSwapFuture(this.tableColumns)
          .then((res) => {
            // visible main columns
            this.$refs.refSwapFutureTable.$el.classList.remove("hide-rows");
            // hide draggable order columns
            var draggableColumns = document.getElementById("draggableColumns");
            draggableColumns.style.display = "none";
            this.showOrderColumns = false;
            this.orderColumnsOverlay = false;
            this.successOrderColumnsMessage();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.wrongToastMessage(
          this.$t("general_messages.columns_data_notfound")
        );
      }
    },
    onCancelOrderColumn() {
      // visible main columns
      this.$refs.refSwapFutureTable.$el.classList.remove("hide-rows");
      // hide draggable order columns
      var draggableColumns = document.getElementById("draggableColumns");
      draggableColumns.style.display = "none";
      this.showOrderColumns = false;
      this.orderColumnsOverlay = false;
    },
    selectTreeColumn(node) {
      if (this.tableOrjinalColumns.find((c) => c.key === node.value)) {
        let columnIndex = this.tableOrjinalColumns.findIndex(
          (i) => i.key === node.value
        );
        let columnData = this.tableOrjinalColumns.find(
          (c) => c.key === node.value
        );
        this.tableColumns.splice(columnIndex, 0, columnData);
      }
    },
    deSelectTreeColumn(node) {
      if (this.tableColumns.find((c) => c.key === node.value)) {
        let columnIndex = this.tableColumns.findIndex(
          (i) => i.key === node.value
        );
        this.tableColumns.splice(columnIndex, 1);
      }
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          ID: trade.ID,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          Cancelled: 0,
        });
      }

      if (status == "Cancel") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (status == "copyTrades") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (trades.length > 0 && status) {
        if (status == "copyTrades") {
          this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
          this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    exportXlsx() {
      let columns = [];
      this.tableColumns.forEach((element) => {
        columns.push(element.key);
      });

      let datas = this.filteredTrades.map((item) => {
        const orderedItem = {};
        columns.forEach((column) => {
          if (column in item) {
            if (column == "Cancelled") {
              if (item[column] == 1) {
                orderedItem[column] = "Cancelled";
              } else {
                orderedItem[column] = "Not Cancelled";
              }
            } else {
              orderedItem[column] = item[column];
            }
          }
        });
        return orderedItem;
      });

      let columnNames = [];

      this.tableColumns.forEach((element) => {
        columnNames.push(element.label);
      });

      store
        .dispatch("swapFuture/exportXlsx", {
          data: datas,
          headings: columnNames,
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "swap-future.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportCsv() {
      let columns = [];
      this.tableColumns.forEach((element) => {
        columns.push(element.key);
      });

      let datas = this.filteredTrades.map((item) => {
        const orderedItem = {};
        columns.forEach((column) => {
          if (column == "EntryType2") {
            orderedItem[column] = "Main Entry";
          }
          if (column in item) {
            if (column == "Cancelled") {
              if (item[column] == 1) {
                orderedItem[column] = "Cancelled";
              } else {
                orderedItem[column] = "Not Cancelled";
              }
            } else {
              orderedItem[column] = item[column];
            }
          }
        });
        return orderedItem;
      });

      let columnNames = [];

      this.tableColumns.forEach((element) => {
        columnNames.push(element.label);
      });

      store
        .dispatch("swapFuture/exportCsv", {
          data: datas,
          headings: columnNames,
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "swap-future.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllAccounts() {
      store
        .dispatch("swapFuture/getAccountCostCenter", { type: "FutureContract" })
        .then((res) => {
          this.optClients = [];
          this.optCounterParties = [];
          this.searchedClients = res.data.clients;
          this.searchedCounterparties = res.data.clients;
          this.optCostCenters = [];
          this.optCostCenters = res.data.cost_centers;
          this.$refs.refFutureContractFormModal.tradeForm.costCenter =
            res.data.default_cost_center;
          this.$refs.refFutureContractFormModal.taxes = res.data.taxes;
          this.$refs.refFutureContractFormModal.defaultTaxRate =
            res.data.default_tax_rate;
          this.$refs.refFutureContractFormModal.commissions =
            res.data.commissions;

          if (res.data.clients.length > 0) {
            res.data.clients.forEach((data) => {
              this.optClients.push(data.AccountName);
            });

            res.data.clients.forEach((data) => {
              this.optCounterParties.push(data.AccountName);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getLast7DaysTrades() {
      this.$checkAccessRight("SwapFuture", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("swapFuture/getLast7DaysTradesSwapFutures")
            .then((res) => {
              this.allSwapFuturesData = [];
              if (res.data.length > 0) {
                this.visibleSwapFutureStatusInput = true;
              } else {
                this.visibleSwapFutureStatusInput = false;
              }
              this.totalRows = res.data.length;
              this.allSwapFuturesData = res.data;
              this.allSwapFutureNotFilterData = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getArrangeSwapFutureOrderTakenGivenThrough() {
      store
        .dispatch(
          "swapFuture/fetchArrangeSwapFutureOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs.push({ title: "All", value: "" });

            if (res.data.orderGivenThroughs.length > 0) {
              res.data.orderGivenThroughs.forEach((data) => {
                this.optOrderGivenThroughs.push({ title: data, value: data });
              });
            }
            this.optOrderTakenVias.push({ title: "All", value: "" });

            if (res.data.orderTakenVias.length > 0) {
              res.data.orderTakenVias.forEach((data) => {
                this.optOrderTakenVias.push({ title: data, value: data });
              });
            }
          }
          // else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelSelectedTrade(item) {
      this.$checkAccessRight("SwapFuture", "Cancel").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.tradeCancelReasonText().then((res) => {
                if (res) {
                  store
                    .dispatch("swapFuture/getCanceledSwapFutureById", {
                      auth: this.user,
                      tradeId: item.ID,
                      reason: this.canceledReasonText,
                      trade: item,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.tradeCancelledMessage();
                        this.runLastSearch();
                        this.canceledReasonText = null;
                      } else {
                        this.tradeCancelledErrorMessage(res.data.message);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  // this.cancelOpearionNotDoneMessage();
                }
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    async autoMatchSameContracts(item) {
      let trade = this.setTradeData(item);
      this.loading = true;

      await this.getFutureContractSpecs();
      const foundContract = await this.optContractNames.find(
        (x) => x.ContractName === trade.contractName
      );

      if (foundContract) {
        trade.ContractID = foundContract.id;
      } else {
        this.warningMessage(
          "Not found existing contract id, please refresh page and continue again."
        );
        this.loading = false;
        return;
      }

      trade.numberOfContracts = Number(trade.numberOfContracts);

      store
        .dispatch("swapFuture/tradeSellMatchOperation", {
          auth: this.user,
          trade: trade,
          matchOption: "auto_match_trade",
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.successMessage(res.data.message);
          } else {
            this.warningMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async manualMatchSameContracts(item) {
      let trade = this.setTradeData(item);
      this.loading = true;

      await this.getFutureContractSpecs();

      const foundContract = await this.optContractNames.find(
        (x) => x.ContractName === trade.contractName
      );

      if (foundContract) {
        trade.ContractID = foundContract.id;
      } else {
        this.warningMessage(
          "Not found existing contract id, please refresh page and continue again."
        );
        this.loading = false;
        return;
      }

      trade.numberOfContracts = Number(trade.numberOfContracts);

      store
        .dispatch("swapFuture/tradeSellMatchOperation", {
          auth: this.user,
          trade: trade,
          matchOption: "manuel_match_trade",
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.successMessage(res.data.message);
          } else if (res.data.info === "manual_match_positions") {
            if (res.data.available_positions.length == 0) {
              this.warningMessage(
                "Not found available positions for manual match operation."
              );
              return;
            }

            const positions = res.data.available_positions.map((pos) => {
              return {
                ...pos,
                openPosition: 0,
              };
            });

            const positionData = {
              success: true,
              positions: positions ?? [],
              trade: trade,
            };

            this.showManualMatchPositionModal(positionData, true);
          } else {
            this.warningMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFutureContractSpecs() {
      return new Promise((resolve, reject) => {
        store
          .dispatch("swapFuture/getFutureContractSpecs", this.user)
          .then((res) => {
            this.optContractNames = res.data;
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          });
      });
    },

    getPairedSelectedTrade(item) {
      this.$checkAccessRight("SwapFuture", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.$router.push({
                path: `/future-contract/selected-paired-trades/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    async tradeCancelReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("swap_future.reason.cancel_reason"),
        text: self.$t("swap_future.reason.please_enter_cancel_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "swap_future.reason.please_enter_cancel_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("swap_future.reason.submit"),
        cancelButtonText: self.$t("swap_future.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != null && reason != null && reason.length > 3) {
              self.canceledReasonText = reason;
              resolve();
            } else {
              resolve(self.$t("swap_future.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          store
            .dispatch("swapFuture/tradeMultipleChangeSwapFutureStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
              reason: result.value ? result.value : null,
            })
            .then((res) => {
              self.loading = false;
              if (res.data.failedProcesses.length > 0) {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData =
                  res.data.failedProcesses;
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                _.mapValues(
                  this.allSwapFuturesData,
                  (v) => (v.selectedTrade = false)
                );
                if (trades.length == 1 && status == "Revers Inititated") {
                  let val = {
                    info: true,
                    tradeId: trades[0].ID,
                    tcUti: trades[0].tcUti,
                    reason: result.value ? result.value : null,
                  };
                  this.reversaledTrade(val);
                }

                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    tradeMultipleSelectCopyOpr(tradesCount, trades) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_copy_title"),
        text: tradesCount + self.$t("multiCheckBoxTitles.trades_copy_message"),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // set trade data
          if (trades.length > 0) {
            var setTradesData = [];
            trades.forEach((trade) => {
              setTradesData.push(self.setTradeData(trade));
            });
          }
          trades = setTradesData;
          store
            .dispatch("swapFuture/tradeMultipleSelectSwapFutureCopyOpr", {
              auth: self.user,
              trades: trades,
            })
            .then((res) => {
              self.loading = false;
              self.selectedMultipleTrades = [];

              if (res.data.failedCopyOpr.length > 0) {
                self.multiSelectCopyWarningMessage();
                self.okMultiSelectProcessModal();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
              } else if (res.data.info == "not_found") {
                this.wrongToastMessage(res.data.message);
              } else {
                self.multiSelectAllTradesCopySuccessMessage();
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    okMultiSelectProcessModal() {
      this.showMultiSelectProcessModal = false;
      this.multiSelectProcessModalTableData = [];
      _.mapValues(this.allSwapFuturesData, (v) => (v.selectedTrade = false));

      this.getLast7DaysTrades();
    },

    searchForTradesModal() {
      this.$checkAccessRight("SwapFuture", "Search").then((res) => {
        if (res.data.valid) {
          this.$refs.searchTrades.searchForTradesModal = true;
          this.$refs.searchTrades.getSavedSearches();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getSearchedData(data) {
      this.totalRows = data.length;
      if (data.length > 0) {
        this.visibleSwapFutureStatusInput = true;
      }
      this.allSwapFuturesData = data;
      this.allSwapFutureNotFilterData = data;
      this.$refs.searchTrades.searchForTradesModal = false;
      this.filterTradeDate = null;
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.expiryRangeDate = null;
      this.filterExpiryDate = null;
    },
    getUpdatedAmendData(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createNewTrade() {
      this.$checkAccessRight("SwapFuture", "Save").then((res) => {
        if (res.data.valid) {
          this.formType = "Create";
          this.formTitle = "Create Future Contract";
          this.formOkButtonText = "swap_future.modals.create_trade_modal_ok";
          this.$refs.refFutureContractFormModal.getSavedDefaultEntries();

          this.$refs.refFutureContractFormModal.openTradeFormModal = true;
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    afterFormSubmitOperation(val) {
      if (val) {
        this.runLastSearch();
        this.formType = null;
        this.formTitle = null;
        this.formOkButtonText = null;
      }
    },
    showManualMatchPositionModal(positionData, fromListManualMatch = false) {
      if (positionData.success) {
        this.$refs.refViewPositionEntries.fetchedTradeData = positionData.trade;
        this.$refs.refViewPositionEntries.availablePositions =
          positionData.positions;
        this.$refs.refViewPositionEntries.viewPositionTradeModal = true;
        this.$refs.refViewPositionEntries.fromListManualMatch = fromListManualMatch;
      }
    },
    createdPositionEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    copiedSwapFuture(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdJournalEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    reversaledTrade(val) {
      const self = this;
      if (val.info) {
        this.runLastSearch();
        this.$swal({
          title: self.$t("swap_future.mail.title"),
          text: self.$t("swap_future.mail.message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("swap_future.mail.send"),
          cancelButtonText: self.$t("swap_future.mail.not_send"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("swapFuture/sendInitiateReversalMail", {
                auth: self.user,
                tcUti: val.tcUti,
                tradeId: val.tradeId,
                reason: val.reason,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  self.sendedMailSuccess();
                } else {
                  self.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    reversedEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    undoInitiate(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    verifiedTrade(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      if (item.ExpiredOrder == 1) {
        return expiredOrderRowColor;
      }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      if (item.EntryType == "Execution") {
        return executedRowColor;
      }
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    runLastSearch(buttonSearch = false) {
      this.filterTradeDate = null;
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.expiryRangeDate = null;
      this.filterExpiryDate = null;

      this.$checkAccessRight("SwapFuture", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("swapFuture/getLastSearch", {
              auth: this.user,
              searchType: "SwapFuture",
            })
            .then((res) => {
              if (res.data != "Not Found") {
                if (res.data.length > 0) {
                  this.allSwapFuturesData = [];
                  if (res.data.length > 0) {
                    this.visibleSwapFutureStatusInput = true;
                  } else {
                    this.visibleSwapFutureStatusInput = true;
                  }
                  this.totalRows = res.data.length;
                  this.allSwapFuturesData = res.data;
                  this.allSwapFutureNotFilterData = res.data;
                  this.loading = false;
                } else {
                  if (buttonSearch) {
                    this.notFoundSearchData();
                  }
                  this.getLast7DaysTrades();
                }
              } else {
                if (buttonSearch) {
                  this.notFoundSearchData();
                }
                this.getLast7DaysTrades();
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    bringUnmatchedTrades() {
      this.$checkAccessRight("SwapFuture", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("swapFuture/fetchUnmatchedTrades")
            .then((res) => {
              this.allSwapFuturesData = [];
              if (res.data.length > 0) {
                this.visibleSwapFutureStatusInput = true;
              } else {
                this.visibleSwapFutureStatusInput = false;
              }
              this.totalRows = res.data.length;
              this.allSwapFuturesData = res.data;
              this.allSwapFutureNotFilterData = res.data;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    setTradeData(val) {
      if (
        val.TimeinForce == "GTC" ||
        val.TimeinForce == "FOK" ||
        val.TimeinForce == "FAK"
      ) {
        val.OrderDurationDate = null;
        val.OrderDurationTime = null;
      }

      let setTrade = {
        costCenter: val.CostCenter,
        client: val.Client ?? null,
        clientAccount: val.ClientAcc ?? null,
        counterparty: val.Cpty ?? null,
        cptyAccount: val.CptyAcc ?? null,
        contractName: val.ContractName ?? null,
        numberOfContracts:
          val.NumberOfContracts != null ? Number(val.NumberOfContracts) : null,
        contractSize:
          val.ContractSize != null ? parseFloat(val.ContractSize) : null,
        contractCcy: val.Ccy ?? null,
        clientPrice:
          val.ClientPrice != null ? parseFloat(val.ClientPrice) : null,
        executionPrice:
          val.ExecutionPrice != null ? parseFloat(val.ExecutionPrice) : null,
        exchangeFee:
          val.ExchangeFee != null ? parseFloat(val.ExchangeFee) : null,
        expiryDate:
          val.ExpiryDate != null ? this.dateFormat(val.ExpiryDate) : null,
        exchangeTraded: val.ExchangeTraded ?? null,
        ticker: val.Ticker ?? null,
        collPerContract:
          val.CollPerContract != null ? parseFloat(val.CollPerContract) : null,
        collTotal: val.CollTotal != null ? parseFloat(val.CollTotal) : null,
        clientCommPerContract:
          val.ClientCommPerContract != null
            ? parseFloat(val.ClientCommPerContract)
            : null,
        clientCommTotal:
          val.ClientCommTotal != null ? parseFloat(val.ClientCommTotal) : null,
        cptyCommPerContract:
          val.CptyCommPerContract != null
            ? parseFloat(val.CptyCommPerContract)
            : null,
        cptyCommTotal:
          val.CptyCommTotal != null ? parseFloat(val.CptyCommTotal) : null,
        netCommission: val.NetComm != null ? parseFloat(val.NetComm) : null,
        spreadGain: val.SpreadGain != null ? parseFloat(val.SpreadGain) : null,
        totalTcProfit:
          val.TotalTcProfit != null ? parseFloat(val.TotalTcProfit) : null,
        orderDurationDate:
          val.OrderDurationDate != null
            ? this.dateFormat(val.OrderDurationDate)
            : null,
        orderDurationTime:
          val.OrderDurationTime != null ? val.OrderDurationTime : null,
        entryType: val.EntryType ?? null,
        buySell: val.BuySell ?? null,
        orderType: val.OrderType ?? null,
        timeInForce: val.TimeinForce ?? null,
        orderDate:
          val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
        orderTime: val.OrderTime != null ? val.OrderTime : null,
        tradeDate:
          val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
        settlementDate:
          val.SettlementDate != null
            ? this.dateFormat(val.SettlementDate)
            : null,
        noticeDate:
          val.NoticeDate != null ? this.dateFormat(val.NoticeDate) : null,
        lastTradeDate:
          val.LastTradeDate != null ? this.dateFormat(val.LastTradeDate) : null,
        executionTime: val.ExecTime != null ? val.ExecTime : null,
        counterpartyTrader: val.CptyTrader ?? null,
        scfUser: val.TcUser ?? null,
        orderTakenVia: val.OrderTakenVia ?? null,
        orderGivenThrough: val.OrderGivenThrough ?? null,
        actingCapaticy: val.ActingCap ?? null,
        counterpartyRef: val.CpRef ?? null,
        hedgeSpec: val.HedgeSpec ?? null,
        settlementType: val.SettlementType ?? null,
        spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
        note: val.TraderNote ?? null,
        confirmationDate:
          val.ConfirmationDate != null
            ? this.dateFormat(val.ConfirmationDate)
            : null,
        confirmationTime:
          val.ConfirmationTime != null ? val.ConfirmationTime : null,
        confirmationTrader: val.ConfirmationTrader ?? null,
        confirmationDateClient:
          val.ConfirmationDateClient != null
            ? this.dateFormat(val.ConfirmationDateClient)
            : null,
        confirmationTimeClient:
          val.ConfirmationTimeClient != null
            ? val.ConfirmationTimeClient
            : null,
        cptyUti: val.CptyUti ?? null,
        tcUti: val.TcUti ?? null,
        uniqueLinkID: val.UniqueLinkId ?? null,
        isin: val.Isin ?? null,
        ID: val.ID,
        tradeRef: val.ID,
      };

      return setTrade;
    },

    getFilterTradeDate(e) {
      if (this.tradeRangeDate != null) {
        this.$checkAccessRight("SwapFuture", "Search").then((res) => {
          if (res.data.valid) {
            let filterTradeDateStart = null;
            let filterTradeDateEnd = null;
            if (
              this.filterTradeDateStart != null &&
              this.filterTradeDateEnd != null
            ) {
              filterTradeDateStart = this.YMDdateFormat(
                this.filterTradeDateStart
              );
              filterTradeDateEnd = this.YMDdateFormat(this.filterTradeDateEnd);
            }

            if (filterTradeDateStart != null && filterTradeDateEnd != null) {
              this.loading = true;
              store
                .dispatch("swapFuture/fetchFilterTradeDate", {
                  auth: this.user,
                  tradeStartDate: filterTradeDateStart,
                  tradeEndDate: filterTradeDateEnd,
                })
                .then((res) => {
                  this.allSwapFuturesData = [];
                  if (res.data.filterDatas.length > 0) {
                    this.visibleSwapFutureStatusInput = true;
                    this.totalRows = res.data.filterDatas.length;
                    this.allSwapFuturesData = res.data.filterDatas;
                  } else {
                    this.visibleSwapFutureStatusInput = false;
                  }

                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            // else {
            //   this.notAuthToastMessage();
            // }
          }
        });
      }
    },
    getFilterExpiryDate() {
      if (this.expiryRangeDate != null) {
        this.$checkAccessRight("SwapFuture", "Search").then((res) => {
          if (res.data.valid) {
            let filterExpiryDateDateStart = null;
            let filterExpiryDateDateEnd = null;

            if (this.filterExpiryDateDateStart != null) {
              filterExpiryDateDateStart = this.YMDdateFormat(
                this.filterExpiryDateDateStart
              );
            }

            if (this.filterExpiryDateDateEnd != null) {
              filterExpiryDateDateEnd = this.YMDdateFormat(
                this.filterExpiryDateDateEnd
              );
            }

            if (
              filterExpiryDateDateStart != null &&
              filterExpiryDateDateEnd != null
            ) {
              this.loading = true;

              store
                .dispatch("swapFuture/fetchFilterExpiryDate", {
                  auth: this.user,
                  expiryStartDate: filterExpiryDateDateStart,
                  expiryEndDate: filterExpiryDateDateEnd,
                })
                .then((res) => {
                  this.allSwapFuturesData = [];
                  if (res.data.filterDatas.length > 0) {
                    this.visibleSwapFutureStatusInput = true;
                    this.totalRows = res.data.filterDatas.length;
                    this.allSwapFuturesData = res.data.filterDatas;
                  } else {
                    this.visibleSwapFutureStatusInput = false;
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
          // else {
          //   this.notAuthToastMessage();
          // }
        });
      }
    },

    getChoice() {
      return this.userData.personal_settings.find(
        (e) => e.key == "future_contract_tickets"
      ).choice;
    },

    // trade verify operation
    verifySelectedTrade(item) {
      if (item.ID != 0) {
        if (item.Cancelled == 0) {
          this.checkTradeOperations(item).then((res) => {
            if (res == true) {
              store
                .dispatch("swapFuture/getVerifySelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    if (this.getChoice()) {
                      this.formType = "Verify";
                      this.formTitle = "Verify Future Contract";
                      this.formOkButtonText =
                        "swap_future.modals.verify_trade_modal_ok";
                      this.$refs.refFutureContractFormModal.fetchedTradeData =
                        res.data.trade;

                      // set changes red color inputs
                      if (res.data.changedInputs) {
                        let setChangedInputs = _.map(
                          res.data.changedInputs,
                          (value, key) => ({ key, value })
                        );
                        if (setChangedInputs.length > 0) {
                          this.$refs.refFutureContractFormModal.changedInputs = setChangedInputs;
                        }
                      }

                      //set timeline datas
                      let actionsArray = [];
                      if (
                        res.data.trade.SavedBy != null &&
                        res.data.trade.SavedBy != null
                      ) {
                        actionsArray.push({
                          type: "Saved",
                          user: res.data.trade.SavedBy ?? null,
                          date:
                            res.data.trade.SaveDate.split(" ")[0] +
                            " " +
                            res.data.trade.SaveTime,
                          reason: "-",
                        });
                      }

                      if (
                        res.data.trade.AmendedBy != null &&
                        res.data.trade.AmendedBy != null
                      ) {
                        actionsArray.push({
                          type: "Amended",
                          user: res.data.trade.AmendedBy ?? null,
                          date:
                            res.data.trade.AmendmentDate.split(" ")[0] +
                            " " +
                            res.data.trade.AmendmentTime,
                          reason: res.data.trade.AmendmentReason,
                        });
                      }
                      if (res.data.actions.length > 0) {
                        if (
                          res.data.actions[0].VerificationUser != null &&
                          res.data.actions[0].VerificationUser != null
                        ) {
                          actionsArray.push({
                            type: "Verified",
                            user: res.data.actions[0].VerificationUser ?? null,
                            date: res.data.actions[0].VerificationDateTime,
                            reason: "-",
                          });
                        }
                      }

                      // if (res.data.actions.length > 0) {
                      //   if (
                      //     res.data.actions[0].JournalUser != null &&
                      //     res.data.actions[0].JournalUser != null
                      //   ) {
                      //     actionsArray.push({
                      //       type: "Journal Created",
                      //       user: res.data.actions[0].JournalUser ?? null,
                      //       date: res.data.actions[0].JournalDateTime,
                      //       reason: "-",
                      //     });
                      //   }
                      // }

                      if (
                        res.data.trade.CancelledBy != null &&
                        res.data.trade.CancelledBy != null
                      ) {
                        actionsArray.push({
                          type: "Canceled",
                          user: res.data.trade.CancelledBy ?? null,
                          date:
                            res.data.trade.CancelDate.split(" ")[0] +
                            " " +
                            res.data.trade.CancelTime,
                          reason: res.data.trade.CancelReason,
                        });
                      }

                      // if (res.data.actions.length > 0) {
                      //   if (
                      //     res.data.actions[0].ReversalInitiatedBy != null &&
                      //     res.data.actions[0].ReversalInitiatedBy != null
                      //   ) {
                      //     actionsArray.push({
                      //       type: "Reversal Initiated",
                      //       user:
                      //         res.data.actions[0].ReversalInitiatedBy ?? null,
                      //       date: res.data.actions[0].ReversalInitiatedDateTime,
                      //       reason: res.data.actions[0].ReversalInitiatedReason,
                      //     });
                      //   }
                      // }

                      // if (res.data.actions.length > 0) {
                      //   if (
                      //     res.data.actions[0].ReversedBy != null &&
                      //     res.data.actions[0].ReversedBy != null
                      //   ) {
                      //     actionsArray.push({
                      //       type: "Reversed",
                      //       user: res.data.actions[0].ReversedBy ?? null,
                      //       date: res.data.actions[0].ReversedDateTime,
                      //       reason: "-",
                      //     });
                      //   }
                      // }

                      this.$refs.refFutureContractFormModal.actionsData = actionsArray;
                      this.$refs.refFutureContractFormModal.openTradeFormModal = true;
                    } else {
                      let verifyList = [];
                      verifyList.push(item);
                      this.tradeMultipleChangeStatusOpr(
                        1,
                        verifyList,
                        "Verify"
                      );
                    }
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        } else {
          this.canceledToastMessage();
        }
      } else {
        this.tradeIDDoesntZeroMessage();
      }
    },

    // trade amendment operations
    amendUpdate(item) {
      this.$checkAccessRight("SwapFuture", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              store
                .dispatch("swapFuture/getAmendSelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.getAllAccounts();
                    this.formType = "Edit";
                    this.formTitle = "Amend Future Contract";
                    this.formOkButtonText =
                      "swap_future.modals.amend_trade_modal_ok";

                    this.$refs.refFutureContractFormModal.fetchedTradeData =
                      res.data.trade;

                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != null
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? null,
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime,
                        reason: "-",
                      });
                    }

                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != null
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? null,
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime,
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != null
                      ) {
                        this.$refs.refFutureContractFormModal.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? null,
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }
                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != null
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? null,
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime,
                        reason: res.data.trade.CancelReason,
                      });
                    }

                    this.$refs.refFutureContractFormModal.actionsData = actionsArray;
                    this.$refs.refFutureContractFormModal.openTradeFormModal = true;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("SwapFuture", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            store
              .dispatch("swapFuture/getViewSelectedTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  // this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                  // this.$refs.ViewSelectedTrade.fetchedTradeData =
                  //   res.data.trade;

                  this.formType = "View";
                  this.formTitle = "View Future Contract";
                  this.formOkButtonText =
                    "swap_future.modals.view_trade_modal_ok";
                  this.$refs.refFutureContractFormModal.fetchedTradeData =
                    res.data.trade;

                  // set changes red color inputs
                  if (res.data.changedInputs) {
                    let setChangedInputs = _.map(
                      res.data.changedInputs,
                      (value, key) => ({ key, value })
                    );
                    if (setChangedInputs.length > 0) {
                      this.$refs.refFutureContractFormModal.changedInputs = setChangedInputs;
                    }
                  }

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != null
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? null,
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != null
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? null,
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != null
                    ) {
                      this.$refs.refFutureContractFormModal.checkVerify = true;
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? null,
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  // if (res.data.actions.length > 0) {
                  //   if (
                  //     res.data.actions[0].JournalUser != null &&
                  //     res.data.actions[0].JournalUser != null
                  //   ) {
                  //     actionsArray.push({
                  //       type: "Journal Created",
                  //       user: res.data.actions[0].JournalUser ?? null,
                  //       date: res.data.actions[0].JournalDateTime,
                  //       reason: "-",
                  //     });
                  //   }
                  // }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != null
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? null,
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  // if (res.data.actions.length > 0) {
                  //   if (
                  //     res.data.actions[0].ReversalInitiatedBy != null &&
                  //     res.data.actions[0].ReversalInitiatedBy != null
                  //   ) {
                  //     actionsArray.push({
                  //       type: "Reversal Initiated",
                  //       user: res.data.actions[0].ReversalInitiatedBy ?? null,
                  //       date: res.data.actions[0].ReversalInitiatedDateTime,
                  //       reason: res.data.actions[0].ReversalInitiatedReason,
                  //     });
                  //   }
                  // }

                  // if (res.data.actions.length > 0) {
                  //   if (
                  //     res.data.actions[0].ReversedBy != null &&
                  //     res.data.actions[0].ReversedBy != null
                  //   ) {
                  //     actionsArray.push({
                  //       type: "Reversed",
                  //       user: res.data.actions[0].ReversedBy ?? null,
                  //       date: res.data.actions[0].ReversedDateTime,
                  //       reason: "-",
                  //     });
                  //   }
                  // }

                  this.$refs.refFutureContractFormModal.actionsData = actionsArray;
                  this.$refs.refFutureContractFormModal.openTradeFormModal = true;
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    copySelectedTrade(item) {
      this.$checkAccessRight("SwapFuture", "Save").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("swapFuture/getCopySelectedTrade", {
              auth: this.user,
              tradeId: item.ID,
              tcUti: item.TcUti,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                if (this.getChoice()) {
                  this.getAllAccounts();

                  this.formType = "Copy";
                  this.formTitle = "Copy Future Contract";
                  this.formOkButtonText =
                    "swap_future.modals.copy_trade_modal_ok";

                  this.$refs.refFutureContractFormModal.fetchedTradeData =
                    res.data.trade;

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != null
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? null,
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != null
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? null,
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != null
                    ) {
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? null,
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != null
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? null,
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  this.$refs.refFutureContractFormModal.actionsData = actionsArray;
                  this.$refs.refFutureContractFormModal.openTradeFormModal = true;
                } else {
                  let verifyList = [];
                  verifyList.push(item);
                  this.tradeMultipleSelectCopyOpr(1, verifyList);
                }
              } else {
                this.notFoundMessage();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getAmendDetails(item) {
      this.$checkAccessRight("SwapFuture", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.$router.push({
                path: `/future-contract/amend-details/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    checkTradeOperations(trade) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("swapFuture/checkTradeVerifyOperation", {
            auth: this.user,
            trade: trade,
          })
          .then((res) => {
            if (res.data.info == "not_verified") {
              store
                .dispatch("swapFuture/checkTradeUserOperation", {
                  auth: this.user,
                  trade: trade,
                })
                .then((res) => {
                  if (res.data.info == "user_doesnt_same") {
                    if (trade.EntryType == "Order") {
                      this.$swal({
                        title: this.$t("swap_future.swal.order_trade"),
                        text: this.$t(
                          "swap_future.swal.entry_saved_order_want_amend_trade"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: this.$t(
                          "swap_future.swal.confirm_button"
                        ),
                        cancelButtonText: this.$t(
                          "swap_future.swal.cancel_button"
                        ),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.amendUpdate(trade);
                        }
                      });
                    } else {
                      resolve(true);
                    }
                  } else if (
                    res.data.info == "same_user_amend" ||
                    res.data.info == "same_user_save"
                  ) {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  } else {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              if (res.data.info == "verified") {
                this.checkTradeVerifyOperationLangMessage(
                  res.data.user,
                  res.data.date
                );
              } else {
                this.checkTradeVerifyOperationMessage(res.data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text:
            this.$t("backend_language_message.verify_trade1") +
            " " +
            user +
            " " +
            this.$t("backend_language_message.verify_trade_on") +
            " " +
            date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUnVerifyOperationLangMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.un_verify_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUserOperationMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("swapFuture/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("swap_future.messages.tc_uti_not_found"));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("swapFuture/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("swap_future.messages.tc_uti_not_found"));
      }
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("swap_future.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("swap_future.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    canceledToastMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.trade_cancelled_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cancelOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.cancelled_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMatchContracts() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.not_found_match_contracts"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    autoMatchContracts() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("swap_future.messages.auto_match_contracts"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    sendedMailSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.mail.mail_send_success"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    tradeCancelledErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(message),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(message),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(message),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("swap_future.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    tradeCancelledMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("swap_future.toast_messages.trade_cancelled_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successOrderColumnsMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("swap_future.toast_messages.success_order_columns"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("swap_future.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    clearTradeDate() {
      this.filterTradeDate = null;
      this.tradeRangeDate = null;
      this.filterTradeDateStart = null;
      this.filterTradeDateEnd = null;
      this.runLastSearch();
    },
    clearExpiryDate() {
      this.filterExpiryDateDateStart = null;
      this.filterExpiryDateDateEnd = null;
      this.expiryRangeDate = null;
      this.filterExpiryDate = null;
      this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // var sticky = this.$refs.refSwapFutureTable.$el.offsetTop;
      // if(this.windowTop + -30 > sticky) {
      //   this.$refs.refSwapFutureTable.$el.children[0].children[0].classList.add("fixed_header_sticky")
      //   //console.log(this.$refs.refSwapFutureTable.$el.children[0].children[0])
      // } else {
      //   this.$refs.refSwapFutureTable.$el.children[0].children[0].classList.remove("fixed_header_sticky")
      // }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("swapFutureTradesShow", val);
      },
    },
    // tableColumns:{
    //   handler: function(val, before) {

    //   },
    // },
    tradeRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterTradeDateStart = this.tradeRangeDate.split(" to ")[0];
            this.filterTradeDateEnd = this.tradeRangeDate.split(" to ")[1];

            if (
              this.filterTradeDateStart != "null" &&
              this.filterTradeDateEnd != "null"
            ) {
            } else {
              this.filterTradeDateStart = null;
              this.filterTradeDateEnd = null;
            }
          }
        }
      },
    },
    expiryRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterExpiryDateDateStart = this.expiryRangeDate.split(
              " to "
            )[0];
            this.filterExpiryDateDateEnd = this.expiryRangeDate.split(
              " to "
            )[1];

            if (
              this.filterExpiryDateDateStart != "null" &&
              this.filterExpiryDateDateEnd != "null"
            ) {
            } else {
              this.filterExpiryDateDateStart = null;
              this.filterExpiryDateDateEnd = null;
            }
          } else {
            this.filterExpiryDateDateStart = this.expiryRangeDate;
          }
        }
      },
    },
    // treeTablecolumns: {
    //   handler: function(val, before) {
    //     this.loading = true;
    //     if (val.length > 0) {
    //       let filteredColumns = [];
    //       filteredColumns.push({
    //         stickyColumn: true,
    //         key: "ID",
    //         label: "ID",
    //         // thClass: "text-left",
    //         tdClass: "text-center ID_background",
    //         // thStyle: 'width: 30%',
    //         // sortable: true,
    //         class: "text-start sticky-column",
    //         variant: "primary",
    //       });
    //       this.tableOrjinalColumns.forEach((o) => {
    //         if (val.find((v) => v.value == o.key)) {
    //           filteredColumns.push(o);
    //         }
    //       });
    //       this.tableColumns = filteredColumns;
    //     } else {
    //       this.tableColumns = this.tableOrjinalColumns;
    //     }
    //     this.loading = false;
    //   },
    // },
    treeStatus: {
      handler: function(val, before) {
        let allFilteredData = [];

        if (val.length > 0) {
          val.forEach((s) => {
            if (s.value == "Execution") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                EntryType: "Execution",
                Verified: 0,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Expired Order") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                EntryType: "Order",
                Verified: 0,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Verified") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Journal Created") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reverse Initiated") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reversed") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 1,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Cancelled") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allSwapFutureNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Cancelled: 1,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            }
          });

          let uniqueData = _.uniqBy(allFilteredData, function(e) {
            return e.ID;
          });

          this.allSwapFuturesData = uniqueData;
          this.totalRows = uniqueData.length;
        } else {
          this.allSwapFuturesData = this.allSwapFutureNotFilterData;
          this.totalRows = this.allSwapFutureNotFilterData.length;
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          // if (
          //   _.filter(val, {
          //     EntryType: "Execution",
          //     Verified: 0,
          //     Cancelled: 0,
          //   }).length > 0
          // ) {
          //   this.multiSelectListStatusData.push({
          //     key: "Verify",
          //     title: this.$t("multiCheckBoxTitles.verify"),
          //     icon: "CheckSquareIcon",
          //     color: "green",
          //   });
          // }
          if (
            _.filter(val, {
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Cancel",
              title: this.$t("multiCheckBoxTitles.cancel"),
              icon: "XSquareIcon",
              color: "#ea5455",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>
.table-executed {
  background-color: #e9fafa !important;
}

.table-verified {
  background-color: #00ff7f !important;
}

.table-journalCreate {
  background-color: #e1d7f5 !important;
}

.table-expiredOrder {
  background-color: silver !important;
}

.table-reverseInitiated {
  background-color: #ffff99 !important;
}

.table-reversed {
  background-color: #f8ac91 !important;
}

.table-canceled {
  background-color: #ffb8b8 !important;
}

.TcUti {
  width: 100%;
}

#trade-confirmation-popover {
  z-index: 9999 !important;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

/* .b-table-sticky-column:last-child {
    right: 0;
  } */

.ID_background {
  background-color: gainsboro !important;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}

.b-table-sticky-header {
  max-height: unset !important;
  height: inherit !important;
}

.hide-rows thead tr[role="row"] {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

.card::v-deep .card-body {
  padding: 0.8rem 0.7rem !important;

  .card-title {
    font-weight: 500 !important;
    font-size: 1.285rem !important;
    margin-bottom: 0.6rem !important;
    text-decoration: none !important;
  }
}

// trade controls buttons
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
