<template>
  <div>
    <b-card class="mb-0" no-body>
      <template #header>
        <div class=" d-flex justify-content-start" style="align-items: center;">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="d-flex"
            style="align-items:center;"
            @click="back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" size="23" />
            <span class="align-middle">{{ $t("general_buttons.back") }}</span>
          </b-button>
          <h4 class="mb-0 ml-2">
            {{ $t("fx_spot_forward_swap.titles.selected_amend_details") }}
          </h4>
        </div>
      </template>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-table
          v-if="tradeDetailDatas.length > 0"
          :sticky-header="true"
          :no-border-collapse="true"
          hover
          :bordered="true"
          ref="refSwapFutureTable"
          class="compact_table"
          thead-class="animate__animated animate__fadeIn"
          :items="tradeDetailDatas"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
          :tbody-tr-class="legendClass"
          caption-top
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortByDesc"
          @row-clicked="viewSelectedTrade"
        >
          <!-- @row-clicked="onRowSelected" -->

          <!-- Column: ID -->
          <template #cell(ID)="data">
            <div class="d-flex justify-content-center align-items-center">
              <b
                ><span class="font-weight-bold text-center ">
                  <strong style="text-decoration:underline;">{{
                    data.item.ID ? data.item.ID : "-"
                  }}</strong>
                </span>
              </b>
            </div>
          </template>

          <!-- Column: TcUti -->
          <template #cell(TcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
              </span></b
            >
          </template>
          <!-- Column: EntryType -->
          <template #cell(EntryType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryType ? data.item.EntryType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderDate -->
          <template #cell(OrderDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderDate ? dateFormat(data.item.OrderDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Trade Date -->
          <template #cell(TradeDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TradeDate != null
                    ? dateFormat(data.item.TradeDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Value Date -->
          <template #cell(ExpiryDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExpiryDate ? dateFormat(data.item.ExpiryDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Client -->
          <template #cell(Client)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Client ? data.item.Client : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ClientAccount -->
          <template #cell(ClientAcc)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ClientAcc ? data.item.ClientAcc : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: BuySell -->
          <template #cell(CptyBuySell)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CptyBuySell ? data.item.CptyBuySell : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ContractName -->
          <template #cell(ContractName)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ContractName ? data.item.ContractName : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: number # of Contracts -->
          <template #cell(NumberOfContracts)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.NumberOfContracts
                    ? data.item.NumberOfContracts
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Contract size -->
          <template #cell(ContractSize)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ContractSize
                    ? formatPrice(data.item.ContractSize, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Client Price -->
          <template #cell(ClientPrice)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ClientPrice
                    ? formatPrice(data.item.ClientPrice, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ccy -->
          <template #cell(Ccy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ccy ? data.item.Ccy : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Client Comm Per Contract -->
          <template #cell(ClientCommPerContract)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ClientCommPerContract
                    ? formatPrice(data.item.ClientCommPerContract, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Total Client Comm -->
          <template #cell(ClientCommTotal)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ClientCommTotal
                    ? formatPrice(data.item.ClientCommTotal, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: counterparty ref -->
          <template #cell(CpRef)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.CpRef ? data.item.CpRef : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cross reference -->
          <template #cell(CrossReference)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CrossReference ? data.item.CrossReference : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Execution Price -->
          <template #cell(ExecutionPrice)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExecutionPrice
                    ? formatPrice(data.item.ExecutionPrice, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Spot Ref -->
          <template #cell(SpotRef)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SpotRef ? formatPrice(data.item.SpotRef, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ExecutionTime -->
          <template #cell(ExecTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExecTime ? data.item.ExecTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ExchangeTraded -->
          <template #cell(ExchangeTraded)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExchangeTraded ? data.item.ExchangeTraded : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ticker -->
          <template #cell(Ticker)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ticker ? data.item.Ticker : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: total collateral -->
          <template #cell(CollTotal)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CollTotal
                    ? formatPrice(data.item.CollTotal, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Coll Per Contract -->
          <template #cell(CollPerContract)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CollPerContract
                    ? formatPrice(data.item.CollPerContract, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Order Type -->
          <template #cell(OrderType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderType ? data.item.OrderType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cpty Comm Per Contract -->
          <template #cell(CptyCommPerContract)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CptyCommPerContract
                    ? formatPrice(data.item.CptyCommPerContract, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Total Cpty Comm -->
          <template #cell(CptyCommTotal)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CptyCommTotal
                    ? formatPrice(data.item.CptyCommTotal, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Net Commission -->
          <template #cell(NetComm)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.NetComm ? formatPrice(data.item.NetComm, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Spread Gain -->
          <template #cell(SpreadGain)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SpreadGain
                    ? formatPrice(data.item.SpreadGain, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Total TC Profit -->
          <template #cell(TotalTcProfit)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TotalTcProfit
                    ? formatPrice(data.item.TotalTcProfit, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Isin -->
          <template #cell(Isin)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Isin ? data.item.Isin : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Hedge/Spec -->
          <template #cell(HedgeSpec)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.HedgeSpec ? data.item.HedgeSpec : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Counterparty UTI -->
          <template #cell(CptyUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CptyUti ? data.item.CptyUti : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: UniqueLinkId -->
          <template #cell(UniqueLinkId)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SettlementType -->
          <template #cell(SettlementType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SettlementType ? data.item.SettlementType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: T&C Confo Date  -->
          <template #cell(ConfirmationDateClient)="data">
            <b
              ><span class="font-weight-bold" style="white-space: nowrap;">
                <strong>{{
                  data.item.ConfirmationDateClient
                    ? dateFormat(data.item.ConfirmationDateClient)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: T&C Confo Time  -->
          <template #cell(ConfirmationTimeClient)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ConfirmationTimeClient
                    ? data.item.ConfirmationTimeClient
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ActingCapacity -->
          <template #cell(ActingCapacity)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ActingCapacity ? data.item.ActingCapacity : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ConfirmationDate  -->
          <template #cell(ConfirmationDate)="data">
            <b
              ><span class="font-weight-bold" style="white-space: nowrap;">
                <strong>{{
                  data.item.ConfirmationDate
                    ? dateFormat(data.item.ConfirmationDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ConfirmationTime  -->
          <template #cell(ConfirmationTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ConfirmationTime ? data.item.ConfirmationTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ConfirmationTrader  -->
          <template #cell(ConfirmationTrader)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ConfirmationTrader
                    ? data.item.ConfirmationTrader
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SettlementDate -->
          <template #cell(SettDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SettDate != null
                    ? dateFormat(data.item.SettDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderTime -->
          <template #cell(OrderTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderTime ? data.item.OrderTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Counterparty Trader -->
          <template #cell(Cpty)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Cpty ? data.item.Cpty : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUser -->
          <template #cell(TcUser)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUser ? data.item.TcUser : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderTakenVia -->
          <template #cell(OrderTakenVia)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderGivenThrough -->
          <template #cell(OrderGivenThrough)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderGivenThrough
                    ? data.item.OrderGivenThrough
                    : "-"
                }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Counterparty -->
          <template #cell(Counterparty)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Counterparty ? data.item.Counterparty : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyAccount -->
          <template #cell(CounterpartyAccount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyAccount
                    ? data.item.CounterpartyAccount
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TraderNote -->
          <template #cell(TraderNote)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TraderNote ? data.item.TraderNote : "-"
                }}</strong>
              </span></b
            >
          </template>
          <!-- Column: SavedBy -->
          <template #cell(SavedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SavedBy ? data.item.SavedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveDate -->
          <template #cell(SaveDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveDate != null
                    ? dateFormat(data.item.SaveDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveTime -->
          <template #cell(SaveTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveTime != null ? data.item.SaveTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendedBy -->
          <template #cell(AmendedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendedBy ? data.item.AmendedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentDate -->
          <template #cell(AmendmentDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentDate != null
                    ? dateFormat(data.item.AmendmentDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentTime -->
          <template #cell(AmendmentTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentTime != null
                    ? data.item.AmendmentTime
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentReason -->
          <template #cell(AmendmentReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentReason ? data.item.AmendmentReason : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cancelled -->
          <template #cell(Cancelled)="data">
            <b
              ><span class="font-weight-bold text-white">
                <strong>{{
                  data.item.Cancelled == 1 ? "Cancelled" : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelledBy -->
          <template #cell(CancelledBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelledBy ? data.item.CancelledBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelDate -->
          <template #cell(CancelDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelDate != null
                    ? dateFormat(data.item.CancelDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelTime -->
          <template #cell(CancelTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelTime != null ? data.item.CancelTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelReason -->
          <template #cell(CancelReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelReason ? data.item.CancelReason : "-"
                }}</strong>
              </span></b
            >
          </template>
        </b-table>

        <div class="mx-2 mb-2" v-if="tradeDetailDatas.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="tradeDetailDatas.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{
                  $t("fx_spot_forward_swap.titles.not_found_trades")
                }}</strong>
                <br />
                {{
                  $t("fx_spot_forward_swap.messages.no_amended_trades_found")
                }}</span
              >
            </div>
          </b-alert>
        </div>
      </b-overlay>

      {{ /*Future contract for trades modal*/ }}
      <FutureContractFormModal ref="refFutureContractFormModal" />
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import swapFutureStoreModule from "../swapFutureStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import FutureContractFormModal from "../FutureContractFormModal.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    FutureContractFormModal,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SWAPFUTURE_APP_STORE_MODULE_NAME = "swapFuture";
    // Register module
    if (!store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SWAPFUTURE_APP_STORE_MODULE_NAME,
        swapFutureStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SWAPFUTURE_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.detailId = this.$route.params.id;
    this.getAmendDetailsData(this.$route.params.id);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      tradeDetailDatas: [],
      detailId: null,
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: [
        // { key: "show_details", label: null },
        {
          stickyColumn: true,
          key: "ID",
          label: "ID",
          // thClass: "text-left",
          tdClass: "text-center ID_background",
          thClass: "sticky-column",
          thStyle: "width: 50px",
          sortable: true,
          class: "text-center table-padding sticky-column",
          variant: "primary",
        },
        {
          key: "EntryType",
          label: this.$t("equity_bond.table.entry_type"),
          class: "text-start table-padding",
        },
        {
          key: "TcUti",
          label: this.$t("swap_future.table.tc_uti"),
          class: "text-start table-padding",
        },
        {
          key: "TradeDate",
          label: this.$t("swap_future.table.trade_date"),
          class: "text-start table-padding text-nowrap",
          sortable: true,
        },
        {
          key: "OrderDate",
          label: this.$t("equity_bond.table.order_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "OrderTime",
          label: this.$t("swap_future.table.order_time"),
          class: "text-start table-padding",
        },
        {
          key: "ExpiryDate",
          label: this.$t("swap_future.table.value_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "Client",
          label: this.$t("swap_future.table.client"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "ClientAcc",
          label: this.$t("swap_future.table.client_account"),
          class: "text-start table-padding",
        },
        {
          key: "CptyBuySell",
          label: this.$t("swap_future.table.buy_sell"),
          class: "text-start table-padding",
        },
        {
          key: "OrderType",
          label: this.$t("swap_future.table.order_type"),
          class: "text-start table-padding",
        },
        {
          key: "ContractName",
          label: this.$t("swap_future.table.contract_name"),
          class: "text-start table-padding",
        },
        {
          key: "NumberOfContracts",
          label: this.$t("swap_future.table.of_contracts"),
          class: "text-start table-padding",
        },
        {
          key: "ContractSize",
          label: this.$t("swap_future.table.contract_size"),
          class: "text-start table-padding",
        },
        {
          key: "ClientPrice",
          label: this.$t("swap_future.table.client_price"),
          class: "text-start table-padding",
        },
        {
          key: "Ccy",
          label: this.$t("swap_future.table.ccy"),
          class: "text-start table-padding",
        },
        {
          key: "ClientCommPerContract",
          label: this.$t("swap_future.table.client_per_contract"),
          class: "text-start table-padding",
        },
        {
          key: "ClientCommTotal",
          label: this.$t("swap_future.table.total_client_comm"),
          class: "text-start table-padding",
        },
        {
          key: "CpRef",
          label: this.$t("swap_future.table.counterparty_ref"),
          class: "text-start table-padding",
        },
        {
          key: "CrossReference",
          label: this.$t("swap_future.table.cross_reference"),
          class: "text-start table-padding",
        },
        {
          key: "ExecutionPrice",
          label: this.$t("swap_future.table.execution_price"),
          class: "text-start table-padding",
        },
        {
          key: "SpotRef",
          label: this.$t("swap_future.table.spot_ref"),
          class: "text-start table-padding",
        },
        {
          key: "ExecTime",
          label: this.$t("swap_future.table.execution_time"),
          class: "text-start table-padding",
        },
        {
          key: "ExchangeTraded",
          label: this.$t("swap_future.table.exchange_traded"),
          class: "text-start table-padding",
        },
        {
          key: "Ticker",
          label: this.$t("swap_future.table.ticker"),
          class: "text-start table-padding",
        },
        {
          key: "CollPerContract",
          label: this.$t("swap_future.table.coll_per_contract"),
          class: "text-start table-padding",
        },
        {
          key: "CollTotal",
          label: this.$t("swap_future.table.total_colleteral"),
          class: "text-start table-padding",
        },
        {
          key: "CptyCommPerContract",
          label: this.$t("swap_future.table.cpty_per_contract"),
          class: "text-start table-padding",
        },
        {
          key: "CptyCommTotal",
          label: this.$t("swap_future.table.total_cpty_comm"),
          class: "text-start table-padding",
        },
        {
          key: "NetComm",
          label: this.$t("swap_future.table.net_comm"),
          class: "text-start table-padding",
        },
        {
          key: "SpreadGain",
          label: this.$t("swap_future.table.spread_gain"),
          class: "text-start table-padding",
        },
        {
          key: "TotalTcProfit",
          label: this.$t("swap_future.table.total_tc_profit"),
          class: "text-start table-padding",
        },
        {
          key: "Isin",
          label: this.$t("swap_future.table.isin"),
          class: "text-start table-padding",
        },
        {
          key: "HedgeSpec",
          label: this.$t("swap_future.table.hedge_spec"),
          class: "text-start table-padding",
        },
        {
          key: "CptyUti",
          label: this.$t("swap_future.table.cpty_uti"),
          class: "text-start table-padding",
        },
        {
          key: "UniqueLinkId",
          label: this.$t("swap_future.table.unique_link_id"),
          class: "text-start table-padding",
        },
        {
          key: "SettlementType",
          label: this.$t("swap_future.table.settlement_type"),
          class: "text-center table-padding",
        },
        {
          key: "ConfirmationDateClient",
          label: this.$t("swap_future.table.ConfirmationDateClient"),
          class: "text-center table-padding",
        },
        {
          key: "ConfirmationTimeClient",
          label: this.$t("swap_future.table.ConfirmationTimeClient"),
          class: "text-center table-padding",
        },
        {
          key: "ActingCapacity",
          label: this.$t("swap_future.table.acting_capacity"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationDate",
          label: this.$t("swap_future.table.CptyConfoDate"),
          class: "text-center table-padding",
        },
        {
          key: "ConfirmationTime",
          label: this.$t("swap_future.table.CptyConfoTime"),
          class: "text-center table-padding",
        },
        {
          key: "ConfirmationTrader",
          label: this.$t("swap_future.table.ConfirmationTrader"),
          class: "text-center table-padding",
        },
        {
          key: "SettDate",
          label: this.$t("swap_future.table.settlement_date"),
          class: "text-start table-padding text-nowrap",
          sortable: true,
        },

        {
          key: "Cpty",
          label: this.$t("swap_future.table.counterparty_trader"),
          class: "text-start table-padding",
        },
        {
          key: "TcUser",
          label: this.$t("swap_future.table.sfc_user"),
          class: "text-start table-padding",
        },

        {
          key: "OrderTakenVia",
          label: this.$t("swap_future.table.order_taken_via"),
          class: "text-start table-padding",
        },
        {
          key: "OrderGivenThrough",
          label: this.$t("swap_future.table.order_given_through"),
          class: "text-start table-padding",
        },
        {
          key: "Counterparty",
          label: this.$t("swap_future.table.counterparty"),
          class: "text-start table-padding",
        },
        {
          key: "CounterpartyAccount",
          label: this.$t("swap_future.table.counterparty_account"),
          class: "text-start table-padding",
        },

        {
          key: "TraderNote",
          label: this.$t("swap_future.table.note"),
          class: "text-start table-padding",
        },

        {
          key: "SavedBy",
          label: this.$t("swap_future.table.saved_by"),
          class: "text-start table-padding",
        },
        {
          key: "SaveDate",
          label: this.$t("swap_future.table.save_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "SaveTime",
          label: this.$t("swap_future.table.save_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendedBy",
          label: this.$t("swap_future.table.amended_by"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentDate",
          label: this.$t("swap_future.table.amendment_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "AmendmentTime",
          label: this.$t("swap_future.table.amendment_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentReason",
          label: this.$t("swap_future.table.amendment_reason"),
          class: "text-start table-padding",
        },
        {
          key: "Cancelled",
          label: this.$t("swap_future.table.cancelled"),
          class: "text-start table-padding",
        },
        {
          key: "CancelledBy",
          label: this.$t("swap_future.table.cancelled_by"),
          class: "text-start table-padding",
        },
        {
          key: "CancelDate",
          label: this.$t("swap_future.table.canceled_date"),
          class: "text-start table-padding text-nowrap",
        },
        {
          key: "CancelTime",
          label: this.$t("swap_future.table.cancelled_time"),
          class: "text-start table-padding",
        },
        {
          key: "CancelReason",
          label: this.$t("swap_future.table.cancelled_reason"),
          class: "text-start table-padding",
        },
      ],

      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,
      loading: false,
      filterSettlementDate: null,
      filterTradeDate: null,
      sortByDesc: true,
      sortBy: "ID",
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    back() {
      router.go(-1);
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      if (item.ExpiredOrder == 1) {
        return expiredOrderRowColor;
      }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      if (item.EntryType == "Execution") {
        return executedRowColor;
      }
    },
    getAmendDetailsData(id) {
      this.loading = true;
      this.$checkAccessRight("SwapFuture", "View").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("swapFuture/getAmendDetailDatas", {
              auth: this.user,
              tradeId: id,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                // this.tradeDetailDatas.push(res.data.trade);
                if (res.data.details.length > 0) {
                  res.data.details.forEach((data) => {
                    this.tradeDetailDatas.push(data);
                  });
                }
                this.totalRows = this.tradeDetailDatas.length;
                this.loading = false;
              } else {
                this.notFoundMessage();
                this.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("SwapFuture", "View").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            if (this.tradeId == item.id) {
              store
                .dispatch("swapFuture/getViewSelectedTrade", {
                  tradeId: item.id,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    // this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    // this.$refs.ViewSelectedTrade.fetchedTradeData =
                    //   res.data.trade;

                    this.formType = "View";
                    this.formTitle = "View Future Contract";
                    this.formOkButtonText =
                      "swap_future.modals.view_trade_modal_ok";
                    this.$refs.refFutureContractFormModal.fetchedTradeData =
                      res.data.trade;

                    // set changes red color inputs
                    if (res.data.changedInputs) {
                      let setChangedInputs = _.map(
                        res.data.changedInputs,
                        (value, key) => ({ key, value })
                      );
                      if (setChangedInputs.length > 0) {
                        this.$refs.refFutureContractFormModal.changedInputs = setChangedInputs;
                      }
                    }

                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != null
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? null,
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime,
                        reason: "-",
                      });
                    }

                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != null
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? null,
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime,
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != null
                      ) {
                        this.$refs.refFutureContractFormModal.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? null,
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }

                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].JournalUser != null &&
                    //     res.data.actions[0].JournalUser != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Journal Created",
                    //       user: res.data.actions[0].JournalUser ?? null,
                    //       date: res.data.actions[0].JournalDateTime,
                    //       reason: "-",
                    //     });
                    //   }
                    // }

                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != null
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? null,
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime,
                        reason: res.data.trade.CancelReason,
                      });
                    }

                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].ReversalInitiatedBy != null &&
                    //     res.data.actions[0].ReversalInitiatedBy != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Reversal Initiated",
                    //       user: res.data.actions[0].ReversalInitiatedBy ?? null,
                    //       date: res.data.actions[0].ReversalInitiatedDateTime,
                    //       reason: res.data.actions[0].ReversalInitiatedReason,
                    //     });
                    //   }
                    // }

                    // if (res.data.actions.length > 0) {
                    //   if (
                    //     res.data.actions[0].ReversedBy != null &&
                    //     res.data.actions[0].ReversedBy != null
                    //   ) {
                    //     actionsArray.push({
                    //       type: "Reversed",
                    //       user: res.data.actions[0].ReversedBy ?? null,
                    //       date: res.data.actions[0].ReversedDateTime,
                    //       reason: "-",
                    //     });
                    //   }
                    // }

                    this.$refs.refFutureContractFormModal.actionsData = actionsArray;
                    this.$refs.refFutureContractFormModal.openTradeFormModal = true;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              store
                .dispatch("swapFuture/getViewAmendedSelectedTrade", {
                  tradeId: item.id,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    this.$refs.ViewSelectedTrade.fetchedTradeData =
                      res.data.trade;

                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? "",
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime.split(" ")[1],
                        reason: "-",
                      });
                    }

                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? "",
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime.split(" ")[1],
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != ""
                      ) {
                        this.$refs.ViewSelectedTrade.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? "",
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].JournalUser != null &&
                        res.data.actions[0].JournalUser != ""
                      ) {
                        actionsArray.push({
                          type: "Journal Created",
                          user: res.data.actions[0].JournalUser ?? "",
                          date: res.data.actions[0].JournalDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != ""
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? "",
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime.split(" ")[1],
                        reason: res.data.trade.CancelReason,
                      });
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversalInitiatedBy != null &&
                        res.data.actions[0].ReversalInitiatedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversal Initiated",
                          user: res.data.actions[0].ReversalInitiatedBy ?? "",
                          date: res.data.actions[0].ReversalInitiatedDateTime,
                          reason: res.data.actions[0].ReversalInitiatedReason,
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversedBy != null &&
                        res.data.actions[0].ReversedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversed",
                          user: res.data.actions[0].ReversedBy ?? "",
                          date: res.data.actions[0].ReversedDateTime,
                          reason: "-",
                        });
                      }
                    }

                    this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found",
          text: this.$t("fx_spot_forward_swap.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t(
            "fx_spot_forward_swap.general_messages.not_authorized_message"
          ),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("fx_spot_forward_swap.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    clearTradeDate() {
      this.filterTradeDate = null;
      this.runLastSearch();
    },
    clearSettlementDate() {
      this.filterSettlementDate = null;
      this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // if(this.windowTop > 300) {
      //   console.log(this.$refs.refFxExecuteTable.$el)
      // }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("AmendDetailsShow", val);
      },
    },
  },
};
</script>

<style>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.table-executed {
  background-color: #e9fafa !important;
}
.table-verified {
  background-color: #00ff7f !important;
}
.table-journalCreate {
  background-color: #e1d7f5 !important;
}
.table-expiredOrder {
  background-color: silver !important;
}
.table-reverseInitiated {
  background-color: #ffff99 !important;
}

.table-reversed {
  background-color: coral !important;
}

.table-canceled {
  background-color: #ffb8b8 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "~/src/assets/scss/compact-design.scss";

.card::v-deep .card-body {
  padding: 0.8rem 0.7rem !important;

  .card-title {
    font-weight: 500 !important;
    font-size: 1.285rem !important;
    margin-bottom: 0.6rem !important;
    text-decoration: none !important;
  }
}
</style>
