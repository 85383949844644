<template>
  <div>
    <b-modal
      id="tradeModal"
      v-model="viewPositionTradeModal"
      :title="$t('swap_future.titles.view_position_entries')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('swap_future.modals.create_positions_modal_ok')"
      :cancel-title="$t('swap_future.modals.modal_cancel')"
      @ok="createFuturePositions"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-0">
                <b-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="12"
                  class="bodyRow mt-1"
                  style="padding: 0.5rem 0;"
                >
                  <b-table
                    striped
                    hover
                    ref="refavailablePositionsTable"
                    class="compact_table"
                    :items="availablePositions"
                    :filter="filter"
                    filter-debounce="250"
                    :filter-included-fields="filterOn"
                    selectable
                    select-mode="single"
                    responsive
                    head-variant="light"
                    :fields="tableColumns"
                    show-empty
                    :empty-text="
                      $t('general_messages.no_matching_recors_found')
                    "
                    :tbody-tr-class="rowClassFunction"
                  >
                    <template #cell(UTI)="data">
                      <strong>{{ data.item.UTI }}</strong>
                    </template>

                    <template #cell(Client)="data">
                      <strong>{{ data.item.Client }}</strong>
                    </template>

                    <template #cell(ClientAcc)="data">
                      <strong>{{ data.item.ClientAcc }}</strong>
                    </template>

                    <template #cell(Cpty)="data">
                      <strong>{{ data.item.Cpty }}</strong>
                    </template>

                    <template #cell(CptyAcc)="data">
                      <strong>{{ data.item.CptyAcc }}</strong>
                    </template>

                    <template #cell(ConractID)="data">
                      <strong>{{ data.item.ConractID }}</strong>
                    </template>

                    <template #cell(created_at)="data">
                      <strong>{{ formatDate(data.item.created_at) }}</strong>
                    </template>

                    <template #cell(NumberOfContract)="data">
                      <strong>{{ data.item.NumberOfContract }}</strong>
                    </template>

                    <template #cell(MatchedNumber)="data">
                      <strong>{{ data.item.MatchedNumber }}</strong>
                    </template>

                    <template #cell(openPosition)="data">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('swap_future.trade_form.open_position')"
                        :rules="{
                          required: true,
                          min_value: 0,
                          max_value:
                            data.item.NumberOfContract -
                            data.item.MatchedNumber,
                        }"
                      >
                        <b-input-group class="compact_form_input-group">
                          <cleave
                            id="openPosition"
                            ref="openPosition"
                            :state="errors.length > 0 ? false : null"
                            v-model="data.item.openPosition"
                            class="form-control"
                            :raw="true"
                            :options="cleaveNumOfConOptions.number"
                            :placeholder="
                              $t('swap_future.trade_form.open_position')
                            "
                            @blur.native="handleChangedOpenPositionInput(data)"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </template>
                  </b-table>
                  <!-- Toplam openPosition -->
                  <div class=" mt-2 border-top">
                    <b-row class=" justify-content-end">
                      <b-row class="p-2">
                        <p>
                          {{ $t("swap_future.titles.total_enter_position") }}:
                          {{ remainingContract }}
                        </p>
                        <strong class="ml-2"
                          >{{ $t("swap_future.titles.total_open_position") }}:
                          {{ totalOpenPosition }}</strong
                        >
                      </b-row>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              {{
                $t("swap_future.overlay_messages.creating_positions_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("swap_future.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("swap_future.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("swap_future.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="createFuturePositions"
            >
              {{ $t("swap_future.modals.view_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import swapFutureStoreModule from "./swapFutureStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SWAPFUTURE_APP_STORE_MODULE_NAME = "swapFuture";
    // Register module
    if (!store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SWAPFUTURE_APP_STORE_MODULE_NAME,
        swapFutureStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SWAPFUTURE_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  data() {
    return {
      viewPositionTradeModal: false,
      fromListManualMatch: false,
      tradeModalLoading: false,
      fetchedTradeData: {},
      actionsData: null,
      availablePositions: [],
      filterOn: ["uti"],
      filter: null,
      tableColumns: [
        {
          key: "UTI",
          label: "UTI",
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "Client",
          label: "Client",
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "ClientAcc",
          label: "Client Account",
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "Cpty",
          label: "Counterparty",
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "CptyAcc",
          label: "Counterparty Account",
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          class: "text-center table-padding white-space",
        },

        {
          key: "NumberOfContract",
          label: "Number of Contract",
          sortable: true,
          class: "text-center table-padding white-space",
        },
        {
          key: "MatchedNumber",
          label: "Matched Number",
          sortable: true,
          class: "text-center table-padding white-space",
        },
        {
          key: "openPosition",
          label: "Open Positions",
          class: "text-center table-padding white-space",
        },
      ],
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //Contract details
        contractName: null,
        numberOfContracts: null,
        contractSize: null,
        clientPrice: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalCcy: null,
        clientCommPerContract: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName: null,
        orderTime: moment().format("HH:mm:ss"),
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        noticeDate: null,
        noticeDateName: null,
        lastTradeDate: null,
        lastTradeDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: "PRINCIPAL",
        hedgeSpec: "HEDGE",
        settlementType: "CASH",
        spotRef: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      // dateConfig: {
      //   allowInput: true,
      //   dateFormat: "d-m-Y",
      //   disable: [
      //     function(date) {
      //       // Cumartesi (6) ve Pazar (0) günlerini devre dışı bırak
      //       return date.getDay() === 0 || date.getDay() === 6;
      //     },
      //   ],
      // },
      cleaveNumOfConOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    totalOpenPosition() {
      return this.availablePositions.reduce(
        (total, item) => parseInt(total) + (parseInt(item.openPosition) || 0),
        0
      );
    },
    remainingContract() {
      return this.tradeForm.numberOfContracts - this.totalOpenPosition ?? 0;
    },

    checkOpenPositionCount() {
      return this.remainingContract == 0;
    },
  },
  methods: {
    handleChangedOpenPositionInput(data) {
      const totalOpen = parseInt(this.totalOpenPosition);

      if (totalOpen > parseInt(this.tradeForm.numberOfContracts)) {
        this.$set(data.item, "openPosition", 0);
        this.warningToastMessage(
          this.$t("swap_future.messages.max_open_position_message"),
          5000
        );
      } else {
        data.item.openPosition = Math.abs(data.item.openPosition);
        const max = data.item.NumberOfContract - data.item.MatchedNumber;
        if (data.item.openPosition > max) {
          this.$set(data.item, "openPosition", 0);
          this.warningToastMessage(
            this.$t("swap_future.messages.max_open_position_message"),
            5000
          );
        }
      }
    },
    createFuturePositions(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;

      if (!this.checkOpenPositionCount) {
        this.warningToastMessage(
          this.$t("swap_future.messages.related_open_position"),
          5000
        );
        return;
      }

      const positions = this.availablePositions.map((item) => {
        const updatedItem = {
          ...item,
          openPosition: parseInt(item.openPosition),
        };

        return updatedItem;
      });

      if (this.tradeForm.tcUti) {
        this.tradeModalLoading = true;
        store
          .dispatch("swapFuture/createManuelFuturePositions", {
            auth: this.user,
            trade: this.tradeForm,
            positions: positions,
            matchOption: "manuel_match_trade",
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.successToastMessage(res.data.message);
              if (this.fromListManualMatch) {
                this.resetTradeModal();
                this.$emit("afterFormSubmitOperation", true);
                this.tradeModalLoading = false;
                return;
              }
              this.handleFormSubmitVerifyOperation();
            } else {
              this.warningToastMessage(res.data.message, 5000);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.tradeModalLoading = false;
          });
      } else {
        this.notFoundUTIValue(5000);
      }
    },
    handleFormSubmitVerifyOperation() {
      this.$checkAccessRight("SwapFuture", "Verify").then(async (res) => {
        if (res.data.valid) {
          if (this.tradeForm.tcUti) {
            this.tradeModalLoading = true;

            store
              .dispatch("swapFuture/tradeVerifyOperation", {
                auth: this.user,
                trade: this.tradeForm,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.successToastMessage(
                    "swap_future.toast_messages.trade_verified_success"
                  );
                  this.resetTradeModal();
                  this.$emit("afterFormSubmitOperation", true);
                  this.tradeModalLoading = false;
                } else {
                  if (res.data.info == "verified") {
                    this.checkTradeVerifyOperationLangMessage(
                      res.data.user,
                      res.data.date
                    );
                  } else if (res.data.info == "user_can_not_verified") {
                    this.warningToastMessage(
                      "backend_language_message.another_user_verify"
                    );
                  } else {
                    this.warningToastMessage(res.data.message);
                  }
                  this.tradeModalLoading = false;
                }
              })
              .catch((error) => {
                this.tradeModalLoading = false;
                console.log(error);
              });
          } else {
            this.warningToastMessage("swap_future.messages.not_found_uti");
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text:
            this.$t("backend_language_message.verify_trade1") +
            " " +
            user +
            " " +
            this.$t("backend_language_message.verify_trade_on") +
            " " +
            date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    rowClassFunction(item, type) {
      if (!item || type !== "row") {
        return;
      }

      return item.dvp ? "dvp-row" : "";
    },

    formatDate(date, format) {
      if (!date) return null;
      return moment(String(date), "DD-MM-YYYY").format("DD.MM.YYYY");
    },
    resetTradeModal() {
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.availablePositions = [];
      this.viewPositionTradeModal = false;
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUnVerifyOperationLangMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.un_verify_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("swap_future.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Not Found UTI",
            text: this.$t("swap_future.messages.not_found_uti"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningToastMessage(message, timeout = 5000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    successToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(message),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    viewPositionTradeModal: {
      handler: function(val, before) {
        if (val) {
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val) {
          this.tradeForm = val;
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.white-space {
  white-space: nowrap;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}

.dvp-row {
  background-color: azure !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
