<template>
  <div class=" mt-2">
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
        Admin Panel
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/user-role">
        Roles and Permissions
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/personal-settings">
        Personal Settings
      </b-breadcrumb-item>
    </b-breadcrumb>

    <b-card
      class="mb-0"
      :title="$t('settings.future_specs.titles.future_contract_specs')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                cle
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
            <b-col v-if="$Can('add_future_spec')" cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  size="sm"
                  variant="success"
                  @click="createNewFutureContractSpecModal"
                >
                  <span class="text-nowrap">
                    {{
                      $t("settings.future_specs.buttons.create_future_specs")
                    }}
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refFutureSpecsTable"
          class="compact_table"
          :items="allFutureSpecs"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          :bordered="true"
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(id)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.item.id }}</strong>
              </span></b
            >
          </template>
          <!-- Column: ContractName -->
          <template #cell(ContractName)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ContractName ? data.item.ContractName : "-"
                }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Ccy -->
          <template #cell(Ccy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ccy ? data.item.Ccy : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Exchange -->
          <template #cell(Exchange)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Exchange ? data.item.Exchange : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ticker -->
          <template #cell(Ticker)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ticker ? data.item.Ticker : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ContractSize -->
          <template #cell(ContractSize)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ContractSize
                    ? formatPrice(data.item.ContractSize)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CollPerContract -->
          <template #cell(CollPerContract)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CollPerContract
                    ? formatPrice(data.item.CollPerContract)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OpenContractType -->
          <template #cell(OpenContractType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OpenContractType ? data.item.OpenContractType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CommissionType -->
          <template #cell(CommissionType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CommissionType ? data.item.CommissionType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Netting -->
          <template #cell(Netting)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Netting ? data.item.Netting : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AssetClass -->
          <template #cell(AssetClass)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AssetClass ? data.item.AssetClass : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ExpiryDate -->
          <template #cell(ExpiryDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExpiryDate ? dateFormat(data.item.ExpiryDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: NoticeDate -->
          <template #cell(NoticeDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.NoticeDate ? dateFormat(data.item.NoticeDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: LastTradeDate -->
          <template #cell(LastTradeDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.LastTradeDate
                    ? dateFormat(data.item.LastTradeDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret dropleft>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="$Can('edit_future_specs')"
                @click="getFutureSpecsDetail(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">
                  {{
                    $t("settings.future_specs.buttons.update_future_spec")
                  }}</span
                >
              </b-dropdown-item>
              <!-- <b-dropdown-item v-if="$Can('delete_future_specs')" @click="deleteFutureSpec(data.item)">
                  <feather-icon
                    :style="'color:#28c76f'
                    "
                    :icon="'Trash2Icon'"
                  />
                  <span class="align-middle ml-50">{{
                    $t("settings.future_specs.buttons.delete_future_spec")
                  }}</span>
                </b-dropdown-item> -->
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted" style="font-size:0.8rem"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 compact_paginate"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      {{ /*future spec create modal*/ }}
      <b-modal
        id="tradeModal"
        style="overflow: inherit;"
        no-close-on-backdrop
        :header-bg-variant="'dark'"
        v-model="futureCreateModal"
        :title="$t('settings.future_specs.titles.create_new_future_spec')"
        @hidden="resetFutureModal"
        size="lg"
        :ok-title="$t('settings.future_specs.buttons.create_future_spec')"
        :cancel-title="$t('settings.future_specs.buttons.cancel')"
        body-class="compact_form_modal"
        footer-class="compact_modal_footer"
        @ok="createFutureSpec"
      >
        <b-overlay :show="createFutureSpecsloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="createValidation">
              <b-form>
                <b-row class="mt-1">
                  <!-- Contract Name-->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ContractName')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ContractName')"
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="contractName"
                          ref="contractName"
                          v-model.trim="futureContractSpecData.ContractName"
                          debounce="500"
                          name="contractName"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            $t('settings.future_specs.form.ContractName')
                          "
                          @change="
                            checkContractName(
                              futureContractSpecData.ContractName
                            )
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- currencies -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group :label="$t('settings.future_specs.form.Ccy')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Ccy')"
                        rules="required"
                      >
                        <v-select
                          ref="ccy"
                          v-model="futureContractSpecData.Ccy"
                          :options="optPriceTypes"
                          :clearable="false"
                          :placeholder="$t('settings.future_specs.form.Ccy')"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Exchange -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Exchange')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Exchange')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.Exchange"
                            ref="exchange"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.Exchange')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Ticker-->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Ticker')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Ticker')"
                        rules="min:3"
                      >
                        <b-form-input
                          id="Ticker"
                          ref="ticker"
                          v-model.trim="futureContractSpecData.Ticker"
                          type="text"
                          name="ticker"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.future_specs.form.Ticker')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- ContractSize -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ContractSize')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ContractSize')"
                      >
                        <b-input-group
                          :append="futureContractSpecData.Ccy"
                          class="compact_form_input-group"
                        >
                          <cleave
                            id="ContractSize"
                            ref="contractSize"
                            :state="errors.length > 0 ? false : null"
                            v-model="futureContractSpecData.ContractSize"
                            class="form-control "
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('settings.future_specs.form.ContractSize')
                            "
                            @blur.native="
                              calculateContractSize(
                                futureContractSpecData.ContractSize
                              )
                            "
                          />
                        </b-input-group>

                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- CollPerContract -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.CollPerContract')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.CollPerContract')"
                        rules="required"
                      >
                        <b-input-group
                          :append="futureContractSpecData.Ccy"
                          class="compact_form_input-group"
                        >
                          <cleave
                            id="CollPerContract"
                            ref="collPerContract"
                            :state="errors.length > 0 ? false : null"
                            v-model="futureContractSpecData.CollPerContract"
                            class="form-control "
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('settings.future_specs.form.CollPerContract')
                            "
                            @blur.native="
                              calculateCollPerContract(
                                futureContractSpecData.CollPerContract
                              )
                            "
                          />
                        </b-input-group>

                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- OpenContractType -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.OpenContractType')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('settings.future_specs.form.OpenContractType')
                        "
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.OpenContractType"
                            ref="exchange"
                            :options="optOpenContractTypes"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.OpenContractType')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- CommissionType -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.CommissionType')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.CommissionType')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.CommissionType"
                            ref="exchange"
                            :options="optCommissionTypes"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.CommissionType')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- Netting -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Netting')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Netting')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.Netting"
                            ref="exchange"
                            :options="optNettings"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.Netting')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- AssetClass -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.AssetClass')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.AssetClass')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.AssetClass"
                            ref="exchange"
                            :options="optAssetClasses"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.AssetClass')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- expiry Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ExpiryDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ExpiryDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.ExpiryDate')
                          "
                          v-model="futureContractSpecData.ExpiryDate"
                          onClose="testClose"
                          ref="expiryDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- notice Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.NoticeDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.NoticeDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.NoticeDate')
                          "
                          v-model="futureContractSpecData.NoticeDate"
                          onClose="testClose"
                          ref="noticeDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- last trade Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.LastTradeDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.LastTradeDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.LastTradeDate')
                          "
                          v-model="futureContractSpecData.LastTradeDate"
                          onClose="testClose"
                          ref="lastTradeDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="createFutureSpecsloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{
                  $t("settings.future_specs.messages.future_creating_message")
                }}
              </p>
            </div>
          </template>
        </b-overlay>

        <!-- modal footer -->
        <template #modal-footer>
          <b-row class="align-items-center justify-content-end mt-1">
            <!-- trade ok operation buttons -->
            <b-col
              cols="12"
              md="12"
              xl="6"
              lg="6"
              sm="12"
              class="d-flex justify-content-end align-items-center mobile-footer"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
                class="ml-1"
                @click="resetFutureModal"
              >
                {{ $t("settings.future_specs.buttons.cancel") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                style="white-space:nowrap;"
                @click="createFutureSpec"
              >
                {{ $t("settings.future_specs.buttons.create_future_spec") }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        {{/****END*** future specs create modal*/}}
      </b-modal>
      {{ /*future spec update modal*/ }}
      <b-modal
        id="tradeModal"
        v-model="futureUpdateModal"
        :title="$t('settings.future_specs.titles.update_future_spec')"
        @hidden="resetFutureModal"
        size="lg"
        :ok-title="$t('settings.future_specs.buttons.update_future_spec')"
        :cancel-title="$t('settings.future_specs.buttons.cancel')"
        @ok="updateFutureSpec"
        style="overflow: inherit;"
        no-close-on-backdrop
        body-class="compact_form_modal"
        footer-class="compact_modal_footer"
        :header-bg-variant="'dark'"
      >
        <b-overlay :show="updateFutureSpecloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="updateValidation">
              <b-form>
                <b-row class="mt-1">
                  <!-- Contract Name-->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ContractName')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ContractName')"
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="contractName"
                          ref="contractName"
                          v-model.trim="futureContractSpecData.ContractName"
                          debounce="500"
                          name="contractName"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            $t('settings.future_specs.form.ContractName')
                          "
                          @change="
                            checkContractName(
                              futureContractSpecData.ContractName
                            )
                          "
                          :disabled="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- currencies -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group :label="$t('settings.future_specs.form.Ccy')">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Ccy')"
                        rules="required"
                      >
                        <v-select
                          ref="ccy"
                          v-model="futureContractSpecData.Ccy"
                          :options="optPriceTypes"
                          :clearable="false"
                          :placeholder="$t('settings.future_specs.form.Ccy')"
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Exchange -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Exchange')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Exchange')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.Exchange"
                            ref="exchange"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.Exchange')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Ticker-->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Ticker')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Ticker')"
                        rules="min:3"
                      >
                        <b-form-input
                          id="Ticker"
                          ref="ticker"
                          v-model.trim="futureContractSpecData.Ticker"
                          type="text"
                          name="ticker"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.future_specs.form.Ticker')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- ContractSize -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ContractSize')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ContractSize')"
                      >
                        <b-input-group
                          :append="futureContractSpecData.Ccy"
                          class="compact_form_input-group"
                        >
                          <cleave
                            id="ContractSize"
                            ref="contractSize"
                            :state="errors.length > 0 ? false : null"
                            v-model="futureContractSpecData.ContractSize"
                            class="form-control "
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('settings.future_specs.form.ContractSize')
                            "
                            @blur.native="
                              calculateContractSize(
                                futureContractSpecData.ContractSize
                              )
                            "
                          />
                        </b-input-group>

                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- CollPerContract -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.CollPerContract')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.CollPerContract')"
                        rules="required"
                      >
                        <b-input-group
                          :append="futureContractSpecData.Ccy"
                          class="compact_form_input-group"
                        >
                          <cleave
                            id="CollPerContract"
                            ref="collPerContract"
                            :state="errors.length > 0 ? false : null"
                            v-model="futureContractSpecData.CollPerContract"
                            class="form-control "
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('settings.future_specs.form.CollPerContract')
                            "
                            @blur.native="
                              calculateCollPerContract(
                                futureContractSpecData.CollPerContract
                              )
                            "
                          />
                        </b-input-group>

                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- OpenContractType -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.OpenContractType')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('settings.future_specs.form.OpenContractType')
                        "
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.OpenContractType"
                            ref="exchange"
                            :options="optOpenContractTypes"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.OpenContractType')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- CommissionType -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.CommissionType')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.CommissionType')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.CommissionType"
                            ref="exchange"
                            :options="optCommissionTypes"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.CommissionType')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- Netting -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.Netting')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.Netting')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.Netting"
                            ref="exchange"
                            :options="optNettings"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.Netting')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- AssetClass -->
                  <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                    <b-form-group
                      :label="$t('settings.future_specs.form.AssetClass')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.AssetClass')"
                        rules="required"
                      >
                        <div class="d-flex align-items-center">
                          <v-select
                            v-model="futureContractSpecData.AssetClass"
                            ref="exchange"
                            :options="optAssetClasses"
                            :clearable="false"
                            :placeholder="
                              $t('settings.future_specs.form.AssetClass')
                            "
                            :reduce="(val) => val"
                            :taggable="false"
                          />
                        </div>
                        <small class="text-danger ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->

                  <!-- expiry Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.ExpiryDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.ExpiryDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.ExpiryDate')
                          "
                          v-model="futureContractSpecData.ExpiryDate"
                          onClose="testClose"
                          ref="expiryDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- notice Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.NoticeDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.NoticeDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.NoticeDate')
                          "
                          v-model="futureContractSpecData.NoticeDate"
                          onClose="testClose"
                          ref="noticeDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- last trade Date -->
                  <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                    <b-form-group
                      :label="$t('settings.future_specs.form.LastTradeDate')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.future_specs.form.LastTradeDate')"
                        rules="required"
                      >
                        <flat-pickr
                          :placeholder="
                            $t('settings.future_specs.form.LastTradeDate')
                          "
                          v-model="futureContractSpecData.LastTradeDate"
                          onClose="testClose"
                          ref="lastTradeDate"
                          class="form-control"
                          :config="dateConfig"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="updateFutureSpecloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{ $t("settings.future_specs.messages.future_update_message") }}
              </p>
            </div>
          </template>
        </b-overlay>
        <!-- modal footer -->
        <template #modal-footer>
          <b-row class="align-items-center justify-content-end mt-1">
            <!-- trade ok operation buttons -->
            <b-col
              cols="12"
              md="12"
              xl="6"
              lg="6"
              sm="12"
              class="d-flex justify-content-end align-items-center mobile-footer"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
                class="ml-1"
                @click="resetFutureModal"
              >
                {{ $t("settings.future_specs.buttons.cancel") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                style="white-space:nowrap;"
                @click="updateFutureSpec"
              >
                {{ $t("settings.future_specs.buttons.update_future_spec") }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        {{/****END*** user update modal*/}}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import Cleave from "vue-cleave-component";
import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      loggedUser: JSON.parse(localStorage.getItem("futureSpecData")),
      allFutureSpecs: [],
      optPriceTypes: [],
      optExchangeTrades: [],
      optOpenContractTypes: ["Contract"],
      optCommissionTypes: ["Basis"],
      optNettings: ["Manual", "FIFO"],
      optAssetClasses: ["Currency"],
      futureCreateModal: false,
      futureUpdateModal: false,
      createFutureSpecsloading: false,
      updateFutureSpecloading: false,
      confrimPassword: null,
      oldUpdateContractName: null,
      oldFutureContractSpecData: null,
      futureContractSpecData: {
        ContractName: null,
        Ccy: null,
        Exchange: null,
        Ticker: null,
        ContractSize: null,
        CollPerContract: null,
        OpenContractType: null,
        CommissionType: null,
        Netting: null,
        AssetClass: null,
        ExpiryDate: null,
        NoticeDate: null,
        LastTradeDate: null,
      },
      filterOn: ["ContractName", "Exchange"],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          class: "text-center table-padding",
        },
        {
          key: "ContractName",
          label: this.$t("settings.future_specs.table.ContractName"),
          sortable: true,
          class: "text-start  table-padding",
        },
        {
          key: "Ccy",
          label: this.$t("settings.future_specs.table.Ccy"),
          sortable: true,
          class: "text-start table-padding",
        },
        {
          key: "Exchange",
          label: this.$t("settings.future_specs.table.Exchange"),
          sortable: true,
          class: "text-start table-padding",
        },
        {
          key: "Ticker",
          label: this.$t("settings.future_specs.table.Ticker"),
          sortable: true,
          class: "text-start table-padding",
        },
        {
          key: "ContractSize",
          label: this.$t("settings.future_specs.table.ContractSize"),
          sortable: true,
          class: "text-start table-padding",
        },
        {
          key: "CollPerContract",
          label: this.$t("settings.future_specs.table.CollPerContract"),
          sortable: true,
          class: "text-start table-padding",
        },
        // {
        //   key: "OpenContractType",
        //   label: this.$t("settings.future_specs.table.OpenContractType"),
        //   sortable: true,
        //   class: "text-start table-padding",
        // },
        // {
        //   key: "CommissionType",
        //   label: this.$t("settings.future_specs.table.CommissionType"),
        //   sortable: true,
        //   class: "text-start table-padding",
        // },
        // {
        //   key: "Netting",
        //   label: this.$t("settings.future_specs.table.Netting"),
        //   sortable: true,
        //   class: "text-start table-padding",
        // },
        // {
        //   key: "AssetClass",
        //   label: this.$t("settings.future_specs.table.AssetClass"),
        //   sortable: true,
        //   class: "text-start table-padding",
        // },
        {
          key: "ExpiryDate",
          label: this.$t("settings.future_specs.table.ExpiryDate"),
          sortable: true,
          class: "text-start table-padding",
        },

        {
          key: "NoticeDate",
          label: this.$t("settings.future_specs.table.NoticeDate"),
          sortable: true,
          class: "text-start table-padding",
        },
        {
          key: "LastTradeDate",
          label: this.$t("settings.future_specs.table.LastTradeDate"),
          sortable: true,
          class: "text-start table-padding",
        },

        {
          key: "actions",
          label: this.$t("settings.future_specs.table.actions"),
          sortable: false,
          class: "text-start table-padding",
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
      expiryDate: null,
      filterExpiryDateStart: null,
      filterExpiryDateEnd: null,

      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getFutureContractSpecs();
  },
  methods: {
    getFutureContractSpecs() {
      this.loading = true;
      store
        .dispatch("settings/fetchFutureContractSpecs")
        .then((res) => {
          this.allFutureSpecs = res.data;
          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    createNewFutureContractSpecModal() {
      this.futureCreateModal = true;
      this.getFutureCurrencies();
      this.getAllExchanges();
    },

    createFutureSpec(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createValidation.validate().then((success) => {
        if (success) {
          this.createFutureSpecsloading = true;

          this.futureContractSpecData.ContractSize =
            this.futureContractSpecData.ContractSize != null
              ? parseFloat(this.futureContractSpecData.ContractSize)
              : null;
          this.futureContractSpecData.CollPerContract =
            this.futureContractSpecData.CollPerContract != null
              ? parseFloat(this.futureContractSpecData.CollPerContract)
              : null;
          this.futureContractSpecData.ExpiryDate =
            this.futureContractSpecData.ExpiryDate != null
              ? this.YMDdateFormat(this.futureContractSpecData.ExpiryDate)
              : null;

          this.futureContractSpecData.NoticeDate =
            this.futureContractSpecData.NoticeDate != null
              ? this.YMDdateFormat(this.futureContractSpecData.NoticeDate)
              : null;

          this.futureContractSpecData.LastTradeDate =
            this.futureContractSpecData.LastTradeDate != null
              ? this.YMDdateFormat(this.futureContractSpecData.LastTradeDate)
              : null;

          store
            .dispatch("settings/createFutureContractSpec", {
              futureContractSpecData: this.futureContractSpecData,
            })
            .then((res) => {
              if (res.data.info == "created") {
                this.createFutureSpecsloading = false;
                this.resetFutureModal();
                this.getFutureContractSpecs();
                this.createContractSpecToastMessage();
              } else if (res.data.info == "exist_contractname") {
                this.createFutureSpecsloading = false;

                this.wrongToastMessage(
                  this.$t("settings.future_specs.messages.contract_name_exist")
                );
              } else {
                this.createFutureSpecsloading = false;
              }
            })
            .catch((error) => {
              this.createFutureSpecsloading = false;
              console.log(error);
            });
        }
      });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));

      return diff;
    },
    updateFutureSpec(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.updateValidation.validate().then((success) => {
        if (success) {
          this.futureContractSpecData.ContractSize =
            this.futureContractSpecData.ContractSize != null
              ? parseFloat(this.futureContractSpecData.ContractSize)
              : null;
          this.futureContractSpecData.CollPerContract =
            this.futureContractSpecData.CollPerContract != null
              ? parseFloat(this.futureContractSpecData.CollPerContract)
              : null;

          if (
            !_.isEqual(
              this.oldFutureContractSpecData,
              this.futureContractSpecData
            )
          ) {
            this.updateFutureSpecloading = true;

            this.futureContractSpecData.ExpiryDate =
              this.futureContractSpecData.ExpiryDate != null
                ? this.YMDdateFormat(this.futureContractSpecData.ExpiryDate)
                : null;

            this.futureContractSpecData.NoticeDate =
              this.futureContractSpecData.NoticeDate != null
                ? this.YMDdateFormat(this.futureContractSpecData.NoticeDate)
                : null;

            this.futureContractSpecData.LastTradeDate =
              this.futureContractSpecData.LastTradeDate != null
                ? this.YMDdateFormat(this.futureContractSpecData.LastTradeDate)
                : null;

            store
              .dispatch("settings/updateFutureContractSpecData", {
                futureContractSpecData: this.futureContractSpecData,
              })
              .then((res) => {
                if (res.data.info == "updated") {
                  this.updateFutureSpecloading = false;
                  this.resetFutureModal();
                  this.getFutureContractSpecs();
                  this.updateContractSpecToastMessage();
                } else {
                  this.updateFutureSpecloading = false;
                  this.wrongToastMessage(
                    this.$t(
                      "settings.future_specs.messages.contract_updated_error"
                    )
                  );
                }
              })
              .catch((error) => {
                this.updateFutureSpecloading = false;
                console.log(error);
              });
          } else {
            this.arrangeInputFormats();
            this.wrongToastMessage(
              this.$t("settings.future_specs.messages.not_changes_message")
            );
          }
        }
      });
    },
    getFutureSpecsDetail(specsId) {
      store
        .dispatch("settings/getFutureSpecsDetail", {
          specsId: specsId,
        })
        .then((res) => {
          if (res.data) {
            this.futureContractSpecData = res.data;
            this.futureContractSpecData.ExpiryDate = this.DMYdateFormat(
              res.data.ExpiryDate
            );

            this.futureContractSpecData.NoticeDate = this.DMYdateFormat(
              res.data.NoticeDate
            );

            this.futureContractSpecData.LastTradeDate = this.DMYdateFormat(
              res.data.LastTradeDate
            );

            this.oldUpdateContractName = JSON.parse(
              JSON.stringify(res.data.ContractName)
            );
            this.oldFutureContractSpecData = JSON.parse(
              JSON.stringify(this.futureContractSpecData)
            );
            this.arrangeInputFormats();
            this.futureUpdateModal = true;
            this.getFutureCurrencies();
            this.getAllExchanges();
          }
        })
        .catch((error) => {
          this.futureUpdateModal = false;
          console.log(error);
        });
    },

    checkContractName(name) {
      if (name != null && name.length > 3) {
        store
          .dispatch("settings/checkContractName", { contractName: name })
          .then((res) => {
            if (res.data.info == "exist_contractname") {
              this.futureContractSpecData.ContractName = null;
              this.wrongToastMessage(
                this.$t("settings.future_specs.messages.contract_name_exist")
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    calculateContractSize() {
      this.arrangeInputFormats();
    },
    calculateCollPerContract() {
      this.arrangeInputFormats();
    },

    arrangeInputFormats() {
      if (this.futureContractSpecData.ContractSize != null) {
        this.futureContractSpecData.ContractSize = Number.parseFloat(
          this.futureContractSpecData.ContractSize
        ).toFixed(2);
      }
      if (this.futureContractSpecData.CollPerContract != null) {
        this.futureContractSpecData.CollPerContract = Number.parseFloat(
          this.futureContractSpecData.CollPerContract
        ).toFixed(2);
      }
    },

    getFutureCurrencies() {
      store
        .dispatch("settings/getFutureCurrencies")
        .then((res) => {
          this.optPriceTypes = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllExchanges() {
      store
        .dispatch("settings/getAllExchanges")
        .then((res) => {
          this.optExchangeTrades = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetFutureModal() {
      this.futureContractSpecData = {
        ContractName: null,
        Ccy: null,
        Exchange: null,
        Ticker: null,
        ContractSize: null,
        CollPerContract: null,
        OpenContractType: null,
        CommissionType: null,
        Netting: null,
        AssetClass: null,
        ExpiryDate: null,
        NoticeDate: null,
        LastTradeDate: null,
      };
      this.futureCreateModal = false;
      this.futureUpdateModal = false;
      this.updateFutureSpecloading = false;
      this.createFutureSpecsloading = false;
      this.oldUpdateContractName = null;
      (this.oldFutureContractSpecData = null),
        //this.allFutureSpecs = [];
        (this.optPriceTypes = []);
      this.optExchangeTrades = [];
      //this.getFutureContractSpecs();
    },

    deleteFutureSpec(spec) {
      // delete Future Spec
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "settings.future_specs.messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createContractSpecToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "settings.future_specs.messages.contract_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    updateContractSpecToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "settings.future_specs.messages.contract_update_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
  },
  watch: {
    //   "futureContractSpecData.email": {
    //     handler: function(val, before) {
    //       console.log(val)
    //     }
    //   },
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    //   expiryDate: {
    //     handler: function(val, before) {
    //       if (val != null) {
    //         if (val.includes("to")) {
    //           this.filterExpiryDateStart = this.expiryDate.split(" to ")[0];
    //           this.filterExpiryDateEnd = this.expiryDate.split(" to ")[1];

    //           this.selectedEvents();
    //         }
    //       }
    //     },
    //   },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73);
}
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "@/assets/scss/compact-design.scss";

.card::v-deep .card-body {
  padding: 0.8rem 0.7rem !important;

  .card-title {
    font-weight: 500 !important;
    font-size: 1.285rem !important;
    margin-bottom: 0.6rem !important;
    text-decoration: none !important;
  }
}

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
