<template>
  <div>
    {{ /*search trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      v-model="searchForTradesModal"
      :title="$t('swap_future.titles.search_for_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('swap_future.modals.search_trade_modal_ok')"
      :cancel-title="$t('swap_future.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="searchTradesloading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- left side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                  <b-card
                    :title="
                      $t('swap_future.titles.client_counterparty_details')
                    "
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.client"
                                ref="client"
                                :options="optClients"
                                :clearable="true"
                                :placeholder="
                                  $t('swap_future.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :filterable="true"
                                @option:selected="selectedClient"
                                class="compact_vue-select"
                                style="width: 100%"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_account')"
                          >
                            <b-form-input
                              id="clientAccount"
                              ref="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.client_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.counterparty')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.counterparty')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.counterparty"
                                ref="cpty"
                                :options="optCounterParties"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :filterable="true"
                                class="compact_vue-select"
                                style="width: 100%;"
                                @option:selected="selectedCounterparty"
                              />
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cpty_account')"
                          >
                            <b-form-input
                              id="cptyAccount"
                              ref="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Order Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.order_details')">
                    <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.order_date')
                                "
                                v-model="orderDateRange"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                                :state="errors.length > 0 ? false : null"
                                ref="orderDate"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearOrderDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_time')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.orderTime"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.orderTimeMax"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_date')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_date')
                            "
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.order_duration_date'
                                  )
                                "
                                v-model="durationDateRange"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                                :state="errors.length > 0 ? false : null"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearDurationDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_duration_time')
                          "
                          class="mobile-label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_duration_time')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.orderDurationTime"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.orderDurationTimeMax"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- entry type -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.entry_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.entry_type')"
                          >
                            <v-select
                              v-model="tradeForm.entryType"
                              ref="entryType"
                              :options="optEntryTypes"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.select_entry_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- buy/sell -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.buy_sell')"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              ref="buySell"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order type-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_type')"
                          >
                            <v-select
                              v-model="tradeForm.orderType"
                              :options="optOrderTypes"
                              ref="orderType"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.select_order_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- time in force-->
                      <b-col cols="6" xl="5" lg="5" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.time_in_force')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.time_in_force')"
                          >
                            <v-select
                              v-model="tradeForm.timeInForce"
                              :options="optTimeInForces"
                              :clearable="true"
                              :multiple="true"
                              ref="timeinForce"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_time_in_force'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_form_vue-multi-select"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost center -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.cost_center')"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              ref="costCenter"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.trade_details')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.trade_date')
                                "
                                v-model="tradeDateRange"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearTradeDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Settlement Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.settlement_date')
                                "
                                v-model="settleDateRange"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearSettleDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_time')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.executionTime"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.executionTimeMax"
                                  :options="cleaveOptions.time"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              ref="cptyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              class="compact_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.sfc_user')"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              ref="tcUser"
                              :options="optScfUsers"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              ref="orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken through -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              ref="orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.acting_capacity')"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              ref="actingCap"
                              :options="optActingCapacities"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Hedge/Spec -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.hedgespec')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.hedgespec')"
                          >
                            <v-select
                              v-model="tradeForm.hedgeSpec"
                              ref="hedgeSpec"
                              :options="optHedgeSpecs"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.select_hedgespec')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- settlement type-->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.settlement_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.settlement_type')"
                          >
                            <v-select
                              v-model="tradeForm.settlementType"
                              ref="settlementType"
                              :options="optSettlementTypes"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Spot Ref -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.spot_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="spot_ref"
                                  ref="spotRef"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spotRef"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="spot_ref"
                                  ref="spotRef"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spotRefMax"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('swap_future.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              ref="note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- right side -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /*******************************Contract Details***************************************************************** */ -->
                  <b-card :title="$t('swap_future.titles.contract_details')">
                    <b-row>
                      <!-- Contract name -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.validation_contract_name'
                              )
                            "
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                ref="contractName"
                                v-model="tradeForm.contractName"
                                :options="optContractNames"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_contract_name'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                :state="errors.length > 0 ? false : null"
                                style="width:100%"
                              />
                              <!-- <i class="fa-solid fa-circle-info fa-xs input-dropdown animate__animated animate__fadeIn"
                          style="padding-left:0.2rem"
                          v-b-tooltip.hover.top="'After entering the contract name you want to add, press enter.'"
                          ></i> -->
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Number of Contracts -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.of_contracts')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.of_contracts')"
                          >
                            <div>
                              <b-input-group>
                                <b-form-input
                                  id="numberOfContracts"
                                  v-model.number="tradeForm.numberOfContracts"
                                  name="numberOfContracts"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                  type="number"
                                  ref="numberOfContracts"
                                />
                                <b-form-input
                                  id="numberOfContracts"
                                  v-model.number="
                                    tradeForm.numberOfContractsMax
                                  "
                                  name="numberOfContracts"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                  type="number"
                                  ref="numberOfContracts"
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Contract Size -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.contract_size')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.contract_size')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  ref="contractSize"
                                  id="contractSize"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.contractSize"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  ref="contractSize"
                                  id="contractSize"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.contractSizeMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- client price -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.client_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.client_price')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  ref="clientPrice"
                                  id="clientPrice"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientPrice"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  ref="clientPrice"
                                  id="clientPrice"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientPriceMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- contract currency type ccy -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.contract_currency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.contract_currency')
                            "
                          >
                            <v-select
                              ref="contractCcy"
                              v-model="tradeForm.contractCcy"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.contract_currency')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- execution price -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.execution_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.execution_price')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  ref="executionPrice"
                                  id="executionPrice"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.executionPrice"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  ref="executionPrice"
                                  id="executionPrice"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.executionPriceMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange Fee -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_fee')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_fee')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  ref="exchangeFee"
                                  id="exchangeFee"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.exchangeFee"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  ref="exchangeFee"
                                  id="exchangeFee"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.exchangeFeeMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- expiry Date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.expiry_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.expiry_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.expiry_date')
                                "
                                v-model="expiryDateRange"
                                onClose="testClose"
                                ref="expiryDate"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearExpiryDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange traded & exchange type -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.exchange_traded')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.exchange_traded')"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.exchangeTraded"
                                ref="exchangeTraded"
                                :options="optExchangeTrades"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'swap_future.trade_form.select_exchange_traded'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="false"
                                style="width:100%"
                              />
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Collateral Per Contract -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.coll_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.coll_per_contract')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="collPerContract"
                                  ref="collPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.collPerContract"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="collPerContract"
                                  ref="collPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.collPerContractMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- total collateral -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_colleteral')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_colleteral')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="collTotal"
                                  ref="collTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.collTotal"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="collTotal"
                                  ref="collTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.collTotalMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Ticker -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.ticker')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.ticker')"
                          >
                            <b-form-input
                              id="ticker"
                              ref="ticker"
                              v-model="tradeForm.ticker"
                              name="ticker"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.ticker')"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Per Contract -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.client_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.client_per_contract')
                            "
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="clientCommPerContract"
                                  ref="clientCommPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientCommPerContract"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="clientCommPerContract"
                                  ref="clientCommPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientCommPerContractMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Comm Total -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.total_client_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.total_client_comm')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientCommTotal"
                                  ref="clientCommTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientCommTotal"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientCommTotal"
                                  ref="clientCommTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientCommTotalMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Per Contract -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.cpty_per_contract')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.cpty_per_contract')
                            "
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="cptyCommPerContract"
                                  ref="cptyCommPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.cptyCommPerContract"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="cptyCommPerContract"
                                  ref="cptyCommPerContract"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.cptyCommPerContractMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Cpty Comm Total -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_cpty_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_cpty_comm')"
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="cptyCommTotal"
                                  ref="cptyCommTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.cptyCommTotal"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="cptyCommTotal"
                                  ref="cptyCommTotal"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.cptyCommTotalMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Net Comission -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.net_comm')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.net_comm')"
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="netCommission"
                                  ref="netCommission"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.netCommission"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="netCommission"
                                  ref="netCommission"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.netCommissionMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Spread Gain -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.spead_gain')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.spead_gain')"
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="spreadGain"
                                  ref="spreadGain"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spreadGain"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="spreadGain"
                                  ref="spreadGain"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.spreadGainMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Total Tc Profit -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.total_tc_profit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.total_tc_profit')"
                          >
                            <div>
                              <b-input-group class="compact_form_input-group">
                                <cleave
                                  id="totalTcProfit"
                                  ref="totalTcProfit"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.totalTcProfit"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.min')
                                  "
                                />

                                <cleave
                                  id="totalTcProfit"
                                  ref="totalTcProfit"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.totalTcProfitMax"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('swap_future.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Notice date -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.notice_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.notice_date')"
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t('swap_future.trade_form.notice_date')
                                "
                                v-model="noticeDateRange"
                                onClose="testClose"
                                ref="noticeDate"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearNoticeDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <!-- /******************************* Settlement Details***************************************************************** */ -->

                  <b-card :title="$t('swap_future.titles.settlement_details')">
                    <b-row>
                      <!-- Cpty Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationDate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationDate')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationDate')
                              "
                              v-model="tradeForm.confirmationDate"
                              onClose="testClose"
                              ref="confirmationDate"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.ConfirmationTime')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTime')
                            "
                          >
                            <cleave
                              id="confirmationTime"
                              ref="confirmationTime"
                              v-model="tradeForm.confirmationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTime')
                              "
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Trader -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTrader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('swap_future.trade_form.ConfirmationTrader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.confirmationTrader"
                              ref="confirmationTrader"
                              :options="optCounterParties"
                              :clearable="true"
                              :placeholder="
                                $t('swap_future.trade_form.ConfirmationTrader')
                              "
                              :reduce="(val) => val"
                              :filterable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client Confo Date -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationDateClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationDateClient'
                              )
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationDateClient'
                                )
                              "
                              v-model="tradeForm.confirmationDateClient"
                              ref="confirmationDateClient"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty Confo Time -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('swap_future.trade_form.ConfirmationTimeClient')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'swap_future.trade_form.ConfirmationTimeClient'
                              )
                            "
                          >
                            <cleave
                              id="confirmationTimeClient"
                              ref="confirmationTimeClient"
                              v-model="tradeForm.confirmationTimeClient"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'swap_future.trade_form.ConfirmationTimeClient'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty MTM Ref  -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.CpRef')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CpRef')"
                          >
                            <b-form-input
                              id="counterpartyRef"
                              ref="CpRef"
                              v-model="tradeForm.counterpartyRef"
                              name="counterpartyRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.CpRef')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Cpty UTI  -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.CptyUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.CptyUti')"
                          >
                            <b-form-input
                              id="cptyUti"
                              ref="cptyUti"
                              v-model="tradeForm.cptyUti"
                              name="cptyUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.CptyUti')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Tc Uti  -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.TcUti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.TcUti')"
                          >
                            <b-form-input
                              id="tcUti"
                              ref="tcUti"
                              v-model="tradeForm.tcUti"
                              name="tcUti"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.TcUti')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.UniqueLinkId')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.UniqueLinkId')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              ref="uniqueLinkId"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('swap_future.trade_form.UniqueLinkId')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- ISIN -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('swap_future.trade_form.isin')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="ISIN"
                          >
                            <b-form-input
                              id="isin"
                              ref="isin"
                              v-model="tradeForm.isin"
                              :name="$t('swap_future.trade_form.isin')"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('swap_future.trade_form.isin')"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- trade ref -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="12">
                        <b-form-group
                          :label="$t('swap_future.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('swap_future.trade_form.trade_ref')"
                          >
                            <b-input-group
                              :prepend="$t('swap_future.trade_form.trade_ref')"
                            >
                              <b-form-input
                                style="background: aliceblue !important;"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('swap_future.trade_form.trade_ref')
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="searchTradesloading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("swap_future.overlay_messages.searching_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingSavedSearch"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showSearchInputs"
                class="float-right compact_form_checkbox"
                style="margin-right:0.8rem"
                plain
              >
                {{ "Save this search" }}
              </b-form-checkbox>

              <validation-observer
                ref="tradeSearchValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showSearchInputs ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showSearchInputs"
                    v-model="searchName"
                    placeholder="Enter search name"
                    id="searchName"
                    name="searchName"
                    class="float-right animate__animated animate__fadeIn compact_form-input"
                    type="text"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                class="btn gradient-info animate__animated animate__fadeIn modal-btn"
                size="sm"
                variant="gradient-info"
                @click="saveSearch"
                v-if="showSearchInputs"
              >
                Save this search
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              text="My Saved Searches"
              dropup
              right
              class="mr-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="savedSearchesList.length > 0"
            >
              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1 modal-btn"
                  style="color:red;"
                  @click="deleteSavedSearch(n.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectSavedSearch(n.SearchData)"
                  >{{ n.SearchName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-secondary mr-1"
              @click="resetSearchForTradesModal"
              size="sm"
            >
              {{ $t("swap_future.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-primary mr-1 modal-btn"
              variant="primary"
              @click="searchForTrades"
              size="sm"
            >
              {{ $t("swap_future.modals.search_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    {{/****END*** search for trades modal*/}}
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import swapFutureStoreModule from "./swapFutureStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import { last } from "lodash";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SWAPFUTURE_APP_STORE_MODULE_NAME = "swapFuture";
    // Register module
    if (!store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SWAPFUTURE_APP_STORE_MODULE_NAME,
        swapFutureStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SWAPFUTURE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SWAPFUTURE_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      searchForTradesModal: false,
      searchTradesloading: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      loadingSavedSearch: false,
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optHedgeSpecs: ["HEDGE", "SPECULATION"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optContractNames: [],
      optPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],

      searchName: null,
      optSearches: [],
      showSearchInputs: false,
      updownButtonText: "down",

      orderDateRange: null,
      tradeDateRange: null,
      settleDateRange: null,
      durationDateRange: null,
      expiryDateRange: null,
      noticeDateRange: null,
      tradeForm: {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //option details
        buySell: null,
        contractName: null,
        numberOfContracts: null,
        numberOfContractsMax: null,
        contractSize: null,
        contractSizeMax: null,
        clientPrice: null,
        clientPriceMax: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceMax: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeMax: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        expiryDateEnd: null,
        noticeDate: null,
        noticeDateEnd: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractMax: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalMax: null,
        collTotalCcy: null,
        orderType: null,
        clientCommPerContract: null,
        clientCommPerContractMax: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalMax: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractMax: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalMax: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionMax: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainMax: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitMax: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: null,
        orderDurationDateName: null,
        orderDurationDateEnd: null,
        orderDurationTime: null,
        orderDurationTimeMax: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateName: null,
        orderDateEnd: null,
        orderTime: null,
        orderTimeMax: null,
        tradeDate: null,
        tradeDateName: null,
        tradeDateEnd: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        settlementDateEnd: null,
        Tplus: null,
        executionTime: null,
        executionTimeMax: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        hedgeSpec: null,
        settlementType: null,
        spotRef: null,
        spotRefMax: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    savedSearchesList() {
      return this.optSearches;
    },
  },
  methods: {
    ...mapActions(["saveLastSearchSwapFuture"]),

    deleteSavedSearch(id) {
      store
        .dispatch("swapFuture/deleteSavedSearch", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {
      this.loadingSavedSearch = true;
      this.tradeForm.orderDateRange = this.orderDateRange;
      this.tradeForm.tradeDateRange = this.tradeDateRange;
      this.tradeForm.settleDateRange = this.settleDateRange;
      this.tradeForm.durationDateRange = this.durationDateRange;
      this.tradeForm.expiryDateRange = this.expiryDateRange;
      this.tradeForm.noticeDateRange = this.noticeDateRange;
      store
        .dispatch("swapFuture/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: "SwapFuture",
          LastSearch: 0,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
          this.loadingSavedSearch = false;
        })
        .catch((error) => {
          this.errorMessage(error);
          this.loadingSavedSearch = false;
          console.log(error);
        });
    },

    selectSavedSearch(savedTrade) {
      if (savedTrade) {
        this.orderDateRange = savedTrade.orderDateRange;
        this.tradeDateRange = savedTrade.tradeDateRange;
        this.settleDateRange = savedTrade.settleDateRange;
        this.durationDateRange = savedTrade.durationDateRange;
        this.expiryDateRange = savedTrade.expiryDateRange;
        this.noticeDateRange = savedTrade.noticeDateRange;
        this.tradeForm = savedTrade;
      }
    },

    getSavedSearches() {
      store
        .dispatch("swapFuture/getSavedSearches", { SearchType: "SwapFuture" })
        .then((res) => {
          if (res.data.savedSearches.length > 0) {
            this.optSearches = _.remove(res.data.savedSearches, {
              LastSearch: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeSwapFutureTCUsers() {
      store
        .dispatch("swapFuture/fetchArangeSwapFutureTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optPriceTypes = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("swap_future.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllExchanges() {
      store
        .dispatch("swapFuture/getAllExchanges")
        .then((res) => {
          this.optExchangeTrades = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFutureContractSpecs() {
      store
        .dispatch("swapFuture/getFutureContractSpecs")
        .then((res) => {
          this.optContractNames = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeSwapFutureOrderTakenGivenThrough() {
      store
        .dispatch(
          "swapFuture/fetchArrangeSwapFutureOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("swap_future.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeSwapFutureCurrencies() {
      store
        .dispatch("swapFuture/fetchArrangeSwapFutureCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("SwapFuture", "Search").then((res) => {
        if (res.data.valid) {
          //set and check search data
          let searchData = this.setSearchData();

          store
            .dispatch("swapFuture/searchSwapFuture", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              this.saveLastSearchSwapFuture(searchData);
              this.resetSearchForTradesModal();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },

    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },

    selectedCounterparty(search) {
      //this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;
      this.optCounterPartyTraders = [];

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },

    clearOrderDate() {
      this.orderDateRange = null;
      this.tradeForm.orderDate = null;
      this.tradeForm.orderDateEnd = null;
    },
    clearDurationDate() {
      this.durationDateRange = null;
      this.tradeForm.orderDurationDate = null;
      this.tradeForm.orderDurationDateEnd = null;
    },
    clearTradeDate() {
      this.tradeDateRange = null;
      this.tradeForm.tradeDate = null;
      this.tradeForm.tradeDateEnd = null;
    },
    clearSettleDate() {
      this.settleDateRange = null;
      this.tradeForm.settlementDate = null;
      this.tradeForm.settlementDateEnd = null;
    },
    clearExpiryDate() {
      this.expiryDateRange = null;
      this.tradeForm.expiryDate = null;
      this.tradeForm.expiryDateEnd = null;
    },
    clearNoticeDate() {
      this.noticeDateRange = null;
      this.tradeForm.noticeDate = null;
      this.tradeForm.noticeDateEnd = null;
    },
    resetSearchForTradesModal() {
      this.optContractNames = [];
      this.optPriceTypes = [];
      this.optExchangeTrades = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.optSearches = [];
      this.showSearchInputs = false;

      this.tradeForm = {
        costCenter: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        //option details
        buySell: null,
        contractName: null,
        numberOfContracts: null,
        numberOfContractsMax: null,
        contractSize: null,
        contractSizeMax: null,
        clientPrice: null,
        clientPriceMax: null,
        contractCcy: null,
        executionPrice: null,
        executionPriceMax: null,
        executionPriceCcy: null,
        exchangeFee: null,
        exchangeFeeMax: null,
        exchangeFeeCcy: null,
        expiryDate: null,
        expiryDateName: null,
        expiryDateEnd: null,
        noticeDate: null,
        noticeDateEnd: null,
        exchangeTraded: null,
        ticker: null,
        collPerContract: null,
        collPerContractMax: null,
        collPerContractCcy: null,
        collTotal: null,
        collTotalMax: null,
        collTotalCcy: null,
        orderType: null,
        clientCommPerContract: null,
        clientCommPerContractMax: null,
        clientCommPerContractCcy: null,
        clientCommTotal: null,
        clientCommTotalMax: null,
        clientCommTotalCcy: null,
        cptyCommPerContract: null,
        cptyCommPerContractMax: null,
        cptyCommPerContractCcy: null,
        cptyCommTotal: null,
        cptyCommTotalMax: null,
        cptyCommTotalCcy: null,
        netCommission: null,
        netCommissionMax: null,
        netCommissionCcy: null,
        spreadGain: null,
        spreadGainMax: null,
        spreadGainCcy: null,
        totalTcProfit: null,
        totalTcProfitMax: null,
        totalTcProfitCcy: null,
        //order details
        orderDurationDate: null,
        orderDurationDateName: null,
        orderDurationDateEnd: null,
        orderDurationTime: null,
        orderDurationTimeMax: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateName: null,
        orderDateEnd: null,
        orderTime: null,
        orderTimeMax: null,
        tradeDate: null,
        tradeDateName: null,
        tradeDateEnd: null,
        tradeEnd: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        settlementDateEnd: null,
        Tplus: null,
        executionTime: null,
        executionTimeMax: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        hedgeSpec: null,
        settlementType: null,
        spotRef: null,
        spotRefMax: null,
        note: null,
        //settle details
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        cptyUti: null,
        tcUti: null,
        uniqueLinkID: null,
        counterpartyRef: null,
        isin: null,
        tradeRef: null,
      };

      this.orderDateRange = null;
      this.tradeDateRange = null;
      this.settleDateRange = null;
      this.durationDateRange = null;
      this.expiryDateRange = null;
      this.noticeDateRange = null;
      this.searchForTradesModal = false;
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("swap_future.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          text: message,
          icon: "TickIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    setSearchData() {
      let setSearch = {
        costCenter: this.tradeForm.costCenter,
        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        cpty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAcc:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        buySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        contractName:
          this.tradeForm.contractName != null
            ? this.tradeForm.contractName
            : null,
        numberOfContracts:
          this.tradeForm.numberOfContracts != null
            ? parseFloat(this.tradeForm.numberOfContracts)
            : null,
        numberOfContractsMax:
          this.tradeForm.numberOfContractsMax != null
            ? parseFloat(this.tradeForm.numberOfContractsMax)
            : null,
        contractSize:
          this.tradeForm.contractSize != null
            ? parseFloat(this.tradeForm.contractSize)
            : null,
        contractSizeMax:
          this.tradeForm.contractSizeMax != null
            ? parseFloat(this.tradeForm.contractSizeMax)
            : null,
        clientPrice:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice)
            : null,
        clientPriceMax:
          this.tradeForm.clientPriceMax != null
            ? parseFloat(this.tradeForm.clientPriceMax)
            : null,
        ccy:
          this.tradeForm.contractCcy != null
            ? this.tradeForm.contractCcy
            : null,
        executionPrice:
          this.tradeForm.executionPrice != null
            ? parseFloat(this.tradeForm.executionPrice)
            : null,
        executionPriceMax:
          this.tradeForm.executionPriceMax != null
            ? parseFloat(this.tradeForm.executionPriceMax)
            : null,
        exchangeFee:
          this.tradeForm.exchangeFee != null
            ? parseFloat(this.tradeForm.exchangeFee)
            : null,
        exchangeFeeMax:
          this.tradeForm.exchangeFeeMax != null
            ? parseFloat(this.tradeForm.exchangeFeeMax)
            : null,
        expiryDate:
          this.tradeForm.expiryDate != null
            ? this.YMDdateFormat(this.tradeForm.expiryDate)
            : null,
        expiryDateEnd:
          this.tradeForm.expiryDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.expiryDateEnd)
            : null,
        noticeDate:
          this.tradeForm.noticeDate != null
            ? this.YMDdateFormat(this.tradeForm.noticeDate)
            : null,
        noticeDateEnd:
          this.tradeForm.noticeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.noticeDateEnd)
            : null,
        lastTradeDate: null,
        lastTradeDateEnd: null,
        exchangeTraded:
          this.tradeForm.exchangeTraded != null
            ? this.tradeForm.exchangeTraded
            : null,
        ticker: this.tradeForm.ticker != null ? this.tradeForm.ticker : null,
        collPerContract:
          this.tradeForm.collPerContract != null
            ? parseFloat(this.tradeForm.collPerContract)
            : null,
        collPerContractMax:
          this.tradeForm.collPerContractMax != null
            ? parseFloat(this.tradeForm.collPerContractMax)
            : null,
        collTotal:
          this.tradeForm.collTotal != null
            ? parseFloat(this.tradeForm.collTotal)
            : null,
        collTotalMax:
          this.tradeForm.collTotalMax != null
            ? parseFloat(this.tradeForm.collTotalMax)
            : null,
        clientCommPerContract:
          this.tradeForm.clientCommPerContract != null
            ? parseFloat(this.tradeForm.clientCommPerContract)
            : null,
        clientCommPerContractMax:
          this.tradeForm.clientCommPerContractMax != null
            ? parseFloat(this.tradeForm.clientCommPerContractMax)
            : null,
        clientCommTotal:
          this.tradeForm.clientCommTotal != null
            ? parseFloat(this.tradeForm.clientCommTotal)
            : null,
        clientCommTotalMax:
          this.tradeForm.clientCommTotalMax != null
            ? parseFloat(this.tradeForm.clientCommTotalMax)
            : null,
        cptyCommPerContract:
          this.tradeForm.cptyCommPerContract != null
            ? parseFloat(this.tradeForm.cptyCommPerContract)
            : null,
        cptyCommPerContractMax:
          this.tradeForm.cptyCommPerContractMax != null
            ? parseFloat(this.tradeForm.cptyCommPerContractMax)
            : null,
        cptyCommTotal:
          this.tradeForm.cptyCommTotal != null
            ? parseFloat(this.tradeForm.cptyCommTotal)
            : null,
        cptyCommTotalMax:
          this.tradeForm.cptyCommTotalMax != null
            ? parseFloat(this.tradeForm.cptyCommTotalMax)
            : null,
        netCommission:
          this.tradeForm.netCommission != null
            ? parseFloat(this.tradeForm.netCommission)
            : null,
        netCommissionMax:
          this.tradeForm.netCommissionMax != null
            ? parseFloat(this.tradeForm.netCommissionMax)
            : null,
        spreadGain:
          this.tradeForm.spreadGain != null
            ? parseFloat(this.tradeForm.spreadGain)
            : null,
        spreadGainMax:
          this.tradeForm.spreadGainMax != null
            ? parseFloat(this.tradeForm.spreadGainMax)
            : null,
        totalTcProfit:
          this.tradeForm.totalTcProfit != null
            ? parseFloat(this.tradeForm.totalTcProfit)
            : null,
        totalTcProfitMax:
          this.tradeForm.totalTcProfitMax != null
            ? parseFloat(this.tradeForm.totalTcProfitMax)
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDateEnd:
          this.tradeForm.orderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDateEnd)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationDateEnd:
          this.tradeForm.orderDurationDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDateEnd)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        orderDurationTimeMax:
          this.tradeForm.orderDurationTimeMax != null
            ? this.timeFormat(this.tradeForm.orderDurationTimeMax)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        tradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        settlementDateEnd:
          this.tradeForm.settlementDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        orderTimeMax:
          this.tradeForm.orderTimeMax != null
            ? this.timeFormat(this.tradeForm.orderTimeMax)
            : null,
        execTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        execTimeMax:
          this.tradeForm.executionTimeMax != null
            ? this.timeFormat(this.tradeForm.executionTimeMax)
            : null,
        counterpartyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCap:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        hedgeSpec:
          this.tradeForm.hedgeSpec != null ? this.tradeForm.hedgeSpec : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        spotRefMax:
          this.tradeForm.spotRefMax != null
            ? parseFloat(this.tradeForm.spotRefMax)
            : null,
        note: this.tradeForm.note != null ? this.tradeForm.note : null,
        confirmationDate:
          this.tradeForm.confirmationDate != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDate)
            : null,
        confirmationTime:
          this.tradeForm.confirmationTime != null
            ? this.timeFormat(this.tradeForm.confirmationTime)
            : null,
        confirmationTrader:
          this.tradeForm.confirmationTrader != null
            ? this.tradeForm.confirmationTrader
            : null,
        confirmationDateClient:
          this.tradeForm.confirmationDateClient != null
            ? this.YMDdateFormat(this.tradeForm.confirmationDateClient)
            : null,
        confirmationTimeClient:
          this.tradeForm.confirmationTimeClient != null
            ? this.timeFormat(this.tradeForm.confirmationTimeClient)
            : null,
        cptyUti: this.tradeForm.cptyUti != null ? this.tradeForm.cptyUti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        cpRef:
          this.tradeForm.counterpartyRef != null
            ? this.tradeForm.counterpartyRef
            : null,
        isin: this.tradeForm.isin != null ? this.tradeForm.isin : null,
        ID: this.tradeForm.tradeRef,
        tcUti: this.tradeForm.tcUti,
      };

      return setSearch;
    },
  },
  watch: {
    "tradeForm.client": {
      handler: function(val, before) {
        if (val == null) {
          this.tradeForm.clientAccount = null;
        }
      },
      deep: true,
    },
    "tradeForm.counterparty": {
      handler: function(val, before) {
        if (val == null) {
          this.tradeForm.cptyAccount = null;
        }
      },
      deep: true,
    },
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getSavedSearches();
          this.getArrangeSwapFutureCurrencies();
          this.getArangeSwapFutureTCUsers();
          this.getArrangeSwapFutureCurrencies();
          this.getArrangeSwapFutureOrderTakenGivenThrough();
          this.getAllExchanges();
          this.getFutureContractSpecs();

          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },

    orderDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDate = val.split("to")[0];
            this.tradeForm.orderDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDate = val;
          }
        }
      },
    },

    tradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.tradeDate = val.split("to")[0];
            this.tradeForm.tradeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.tradeDate = val;
          }
        }
      },
    },

    settleDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.settlementDate = val.split("to")[0];
            this.tradeForm.settlementDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.settlementDate = val;
          }
        }
      },
    },

    durationDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDurationDate = val.split("to")[0];
            this.tradeForm.orderDurationDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDurationDate = val;
          }
        }
      },
    },

    expiryDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.expiryDate = val.split("to")[0];
            this.tradeForm.expiryDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.expiryDate = val;
          }
        }
      },
    },
    noticeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.noticeDate = val.split("to")[0];
            this.tradeForm.noticeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.noticeDate = val;
          }
        }
      },
    },
  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}

.animate__animated {
  animation-fill-mode: none;
}

.form-group.prop_checkbox {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// multiple vue select input
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.6rem !important;
}
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// multiple vue select input
.compact_form_vue-multi-select::v-deep .vs__selected {
  position: relative !important;
}

//
</style>
